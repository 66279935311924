import React, { Component } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import "./style.css";
import * as Costants from "../utils/Constants";
import * as Api from "../utils/Api";
import * as common from "../utils/Common";
import Notification from "../Components/AlertNotification";
import * as Validate from "../utils/validatorXerox";

import axios from "axios";

export default class BuiltupUnit extends Component {
  constructor() {
    super();
    this.state = {
      UnitType: [],
      floor: "Select Floor",
      Type: "Select Unit Type",
      companyname: "company name",
      Area: "Total Area",
      Type_id: "",
      select_area: "",
      name: "",
      modal: false,
      show: false,
      floorData: [],
      floor_id: "",
      area_detail: [],
      area_id: "",
      renderdata: [],
      isPopupAction: "",
      update_id: "",
      delete_id: "",
      companyId: "",
      location: "",
      unallocatedArea: "",
      toggle: false
    };
  }

  UNSAFE_componentWillMount() {
    // this.handlegetCompany();
    this.GetCompanyFloors();
    this.GetShowData();
    
    this.setState({ location: this.props.location.state.location });
  }

  // /*-----------------------get flooor---------------------------*/
  GetCompanyFloors = () => {
    console.log("company------>", this.props.location.state);
    let company_id = this.props.location.state
      ? this.props.location.state.company_id
      : "";
    let response = Api.GetData(this, "builtupunit/getCompanyFloor", {
      company_id: company_id
    });
    response.then(data => {
       //console.log("datatattat--------->", data.BuildingAddress.buildingName);
      // data.data.map(value=>{
      //   this.GetBuiltUpUnits(value.id)
      // })

      if (!data) {
        return;
      }

      this.setState({ floorData: data.data });
      if (data.data.length > 0) {
        let firstData = data.data[0];
        let secondData = data.BuildingAddress.buildingName
       // console.log("hgdhg---->",secondData)
        this.setState({
          floor_id: firstData.buildingFloorId,
          floor: firstData.buildingFloor.name,
          area_id: firstData.id,
          companyname: firstData.company.name,
          Area: firstData.area,
          location:secondData
        });

        this.GetBuiltUpUnits(firstData.id);
      }
    });
  };

  // /*-----------------------get flooor---------------------------*/

  GetBuiltUpUnits = area => {
    // console.log("area------->", area);
    let response = Api.GetData(this, `builtupunit/get_type/${area}`, {});
    response.then(data => {
      console.log("data------------>", data);
      if (!data) {
        return;
      }

      var a = 0;
      if (data.data) {
       // console.log("hiiiiiiiiiiiiii");
        for (let i = 0; i < data.data.length; i++) {
          a += data.data[i].area;
        }
        console.log("looop", a);
      }
      var remainingArea = this.state.Area - a;

      this.setState({ renderdata: data.data, unallocatedArea: remainingArea });
    });
  };

  CreateBuiltupUnits = () => {
    const data_post = {
      area: this.state.select_area,
      builtupUnitTypeId: this.state.Type_id,
      builtupAreaId: this.state.area_id,
      name: this.state.name
    };
    let validate = Validate.dynamicValidationNew("CreateCompany", data_post);
   
    if (validate !== "" ) {
      this.refs.Notification.openNotification("danger", validate);
      return;
    }

    let response = Api.PostData(
      this,
      `builtupunit/create_builtiup_units`,
      data_post
    );
    response.then(data => {
       console.log("post--->",data)
     if (!data){
           return
     }
      if (data.status == 1) {
        this.refs.Notification.openNotification("success", data.message);
        this.GetBuiltUpUnits(this.state.area_id);
        this.modalClose();

        this.setState({ Type: "select Unit Type", select_area: "" });
      } else {
        this.refs.Notification.openNotification("danger", data.message);
        this.modalClose();
      }
     
       
    });

    // axios({
    //   method: "post",
    //   url: "http://localhost:3101/builtupunit/create_builtiup_units",
    //   headers: { "content-type": "application/json" },
    //   data: data_post
    // })
    //   .then(res => {
    //     console.log("response Post------->", res);
    //     this.modalClose();
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  };

  UpdateUnits = () => {
    //   const data = {
    //     area: this.state.select_area,
    //     builtupUnitTypeId: this.state.Type_id
    //   };
    //  // console.log("data----------->",this.state.Type_id)
    //   let response = Api.PutData(
    //     this,
    //     `builtupunit/update_builtupUnit/${this.state.update_id}`,
    //     data
    //   );
    //   response.then(data => {
    //     console.log("data----",data)
    //     if(data.status=200){
    //     this.modalClose();
    //     this.GetBuiltUpUnits(this.state.area_id);
    //     this.setState({Type:"Select Unit Type",select_area:""})
    //    }
    //   });
  };

  /*----------------------postapi in save button-------------------------------*/

  handleSubmit(e) {
    e.preventDefault();
    let token = common.read("token");

    if (this.state.isPopupAction === "save") {
      this.CreateBuiltupUnits();
    } else {
      // this.UpdateUnits();
    }
  }

  modalOpen() {
    this.setState({ modal: true });
  }

  modalClose() {
    this.setState({
      modal: false,
      Type: "Select Unit Type",
      select_area: "",
      name: ""
    });
  }

  handleChange_input(e) {
    this.setState({ select_area: e.target.value });
  }

  handleChange_inputname(e) {
    this.setState({ name: e.target.value });
  }

  handleTextChangedropdown = data => {
    // console.log("dropdown data----->", data);
    this.setState({
      floor_id: data.buildingFloorId,
      floor: data.buildingFloor.name,
      area_id: data.id,
      companyname: data.company.name,
      Area: data.area
    });

    this.GetBuiltUpUnits(data.id);
  };

  handleToggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  handlePopupDrop = e => {
    // console.log("dropdown in popup------->", e);
    this.setState({ Type: e.type_name, Type_id: e.id });
  };

  /*------------------------------get Api in popup's drop down for Unit type-------------------*/

  GetShowData = () => {
    let response = Api.GetData(this, `builtupunit/unit_types`, {});
    response.then(data => {
      if (!data) {
        return;
      }
      console.log("unit type---->", data.data);
      this.setState({ UnitType: data.data });
    });
  };
  /*-----------------------update Api--------------*/
  updatetype = (e, data) => {
    console.log("data---->",data)
    e.preventDefault();
    this.props.history.push({
      pathname: "/admin/amenities",
      state: {
        data: data,
        company_id: this.props.location.state.company_id,
        location:this.props.location.state.location
      }
    });

    this.setState({
      Type: data.builtupUnit_type.type_name,
      Type_id: data.builtupUnit_type.id,
      select_area: data.area,
      isPopupAction: "update",
      update_id: data.id
    });
  };

  deletetype = (e, id) => {
    // console.log("id------------>", id);
    e.preventDefault();
    this.setState({ show: true, delete_id: id.id });
  };

  DeleteApi = () => {
    let deleteData = Api.deleteData(
      this,
      `builtupunit/deleteBuiltupUnit/${this.state.delete_id}`
    );
    deleteData
      .then(res => {
        console.log("delete-res------>", res);
        if (res.status === 1) {
          this.refs.Notification.openNotification("success", res.message);
          this.setState({ show: false });
          this.GetBuiltUpUnits(this.state.area_id);
        } else {
          this.refs.Notification.openNotification("danger", res.message);
        }
      })
      .catch(error => {
        console.log("delete-error------>", error);
      });
  };

  modalClosedel = () => {
    this.setState({ show: false });
  };

  formatInput = (e) => {

    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-" ;
    }
    else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  }


  render() {
    return (
      <React.Fragment>
        <div className="main-content">
          <div className="container-fluid">
            <Notification ref="Notification" />
            <div className="row justify-content-center">
              <div className="col-12 ">
                <div className="header mt-md-5">
                  <div className="header-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h1 className="header-title">Builtup Unit</h1>
                      </div>
                      <div className="col-auto">
                        <button
                          disabled={this.state.floor_id ? false : true}
                          className="btn btn-primary lift"
                          type="button"
                          id="bulkActionDropdown"
                          onClick={() =>
                            this.setState({
                              isPopupAction: "save",
                              modal: true
                            })
                          }
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Add Builtup Unit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card"
                  data-toggle="lists"
                  data-options='{"valueNames": ["orders-order", "orders-product", "orders-date", "orders-total", "orders-status", "orders-method"]}'
                >
                  <div className="card-header">
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col">
                                {/* Title */}
                                <h6 className="card-title text-uppercase text-muted mb-2">
                                  Company Name
                                </h6>
                                {/* Heading */}
                                <span className="h2 mb-0">
                                  {" "}
                                  {this.state.companyname}
                                </span>
                                {/* Badge */}
                                {/* <span className="badge badge-soft-success mt-n1">
                          +3.5%
                        </span> */}
                              </div>
                            </div>{" "}
                            {/* / .row */}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col">
                                {/* Title */}
                                <h6 className="card-title text-uppercase text-muted mb-2">
                                  Company Location
                                </h6>
                                {/* Heading */}
                                <span className="h2 mb-0">
                                  {" "}
                                  <div
                                  // style={{
                                  //   whiteSpace: "nowrap",
                                  //   width:200,
                                  //   overflow: "hidden",
                                  //   textOverflow: "ellipsis"
                                  // }}
                                  >
                                    {this.state.location}
                                  </div>
                                </span>
                                {/* Badge */}
                                {/* <span className="badge badge-soft-success mt-n1">
                        +3.5%
                      </span> */}
                              </div>
                            </div>{" "}
                            {/* / .row */}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col">
                                {/* Title */}
                                <h6 className="card-title text-uppercase text-muted mb-2">
                                  Total Area
                                </h6>
                                {/* Heading */}
                                <span className="h2 mb-0">
                                  {" "}
                                  {this.state.Area}
                                </span>
                                {/* Badge */}
                                {/* <span className="badge badge-soft-success mt-n1">
                              +3.5%
                            </span> */}
                              </div>
                              <div className="col-auto">
                                <h6 className="card-title text-uppercase text-muted mb-2">
                                  Unused Area
                                </h6>
                                <span className="h2 mb-0">
                                  {" "}
                                  {this.state.unallocatedArea}
                                </span>
                              </div>
                            </div>{" "}
                            {/* / .row */}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-3">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ padding: ".4rem" }}
                          >
                            <div className="row align-items-center">
                              <div className="col">
                                {/* Title */}
                                <h6 className="card-title text-uppercase text-muted mb-2">
                                  Floor Name
                                </h6>
                                {/* Heading */}
                                <span className="h2 mb-0">
                                  {" "}
                                  {
                                    <Dropdown
                                      className="dropdown"
                                      onClick={this.handleToggle}
                                    >
                                      <Dropdown.Toggle>
                                        <button
                                          className="btn btn-white dropdown-toggle btn-block"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          //style={{ width: "100%",position:"relative",margin:"0 auto",lineheight:"1.4em" }}
                                        >
                                          {this.state.floor}
                                          <span style={{textAlign:'left'}}>
                                          {
                                            
                                            <i className="fe fe-chevron-down"></i>
                                          }
                                          </span>
                                        </button>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {this.state.floorData.map(
                                          (k, index) => {
                                            return (
                                              <Dropdown.Item
                                                key={index}
                                                onClick={this.handleTextChangedropdown.bind(
                                                  this,
                                                  k
                                                )}
                                              >
                                                {k.buildingFloor.name}
                                              </Dropdown.Item>
                                            );
                                          }
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  }
                                </span>
                                {/* Badge */}
                                {/* <span className="badge badge-soft-success mt-n1">
                        +3.5%
                      </span> */}
                              </div>
                            </div>{" "}
                            {/* / .row */}
                          </div>
                        </div>
                        <div className="form-group"></div>
                      </div>
                    </div>
                  </div>

                  {/*------------------Confirmation modal------------------------------*/}
                  <div>
                    <Modal
                      show={this.state.show}
                      onHide={this.modalClosedel}
                      backdrop="static"
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <h1>Are You Sure, you want to delete this ? </h1>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button variant="primary" onClick={this.DeleteApi}>
                          OK
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={this.modalClosedel}
                        >
                          Cancel
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>

                  {/*------------------unittype modal------------------------------*/}
                  <Modal
                    show={this.state.modal}
                    onHide={e => this.modalClose(e)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>BuiltUp Unit</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <div className="row align-items-center">
                        <div className="col-12 col-md-4">
                          <Dropdown className="dropdown">
                            <Dropdown.Toggle
                              style={{
                                width: "100%"
                              }}
                            >
                              <button
                                className="btn btn-white dropdown-toggle btn-block"
                                type="button"
                              >
                                {this.state.Type}
                              </button>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {this.state.UnitType.map((k, index) => {
                                return (
                                  <Dropdown.Item
                                    key={index}
                                    onClick={this.handlePopupDrop.bind(this, k)}
                                  >
                                    {k.type_name}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <input
                              className="form-control capitalize"
                              type="text"
                              placeholder=" enter name"
                              style={{ marginTop: "20px" }}
                              onInput={this.handleChange_inputname.bind(this)}
                              value={this.state.name}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="number"
                              placeholder="  enter area"
                              style={{ marginTop: "20px" }}
                              pattern="[0-9]*"
                              onInput={this.handleChange_input.bind(this)}
                              value={this.state.select_area}
                              onKeyDown={this.formatInput}
                            
                            />
                          </div>
                        </div>
                      </div>
                      <Modal.Footer>
                        <Button
                          onClick={e => this.handleSubmit(e)}
                          type="button"
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal.Body>
                  </Modal>
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      <thead>
                        <tr>
                          <th>
                            <a
                              // href="#"
                              //onClick={this.sortfunc.bind(this, "f_name")}
                              className="text-muted sort"
                              data-sort="orders-product"
                            >
                              Unit Type
                            </a>
                          </th>
                          <th>
                            <a
                              // href="#"
                              //onClick={this.sortfunc.bind(this, "f_name")}
                              className="text-muted sort"
                              data-sort="orders-product"
                            >
                              Sub Area
                            </a>
                          </th>
                          <th>
                            <a
                              // href="#"
                              //onClick={this.sortfunc.bind(this, "f_name")}
                              className="text-muted sort"
                              data-sort="orders-product"
                            >
                              Name
                            </a>
                          </th>
                          <th></th>
                          <th></th>
                        </tr>
                        {this.state.renderdata.map((k, index) => {
                          return (
                            <tr key={index}>
                              <td>{k.builtupUnit_type.type_name}</td>

                              <td>{k.area}</td>
                              <td>{k.detail === null ? "" : k.detail.name}</td>

                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end"
                                  }}
                                >
                                  <Button onClick={e => this.updatetype(e, k)}>
                                    Update
                                  </Button>

                                  <Button
                                    style={{ marginLeft: "20px" }}
                                    variant="secondary"
                                    onClick={e => this.deletetype(e, k)}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
