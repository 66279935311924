import React, { Component } from "react";
import Loader from "react-loader-spinner";

export default class Spinner extends React.Component {
  state = {
    visible: false
  };

  render() {
    return (
      <Loader
        type="Puff"
        color="#00BFFF"
        height={20}
        width={20}
        // timeout={3000} //3 secs
        visible={this.props.status}
      />
    );
  }
}
