import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone";
import "react-datepicker/dist/react-datepicker.css";
import * as Api from "../utils/Api";
import * as Constant from "../utils/Constants";
import Notification from "../Components/AlertNotification";
import * as Validate from "../utils/validatorXerox";
import * as Costants from "../utils/Constants";
import Select from "react-select";

export default class AddLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateid: "",
      btntext: "Create Location",
      typearray: [],
      Statearray: [],
      cityarray: [],
      Locationname: "",
      Address: "",
      State: "",
      City: "",
      Type: "",
      selectedState: null,
      selectedCity: null,
      selectedLocality: null,
      floors: [
        {
          floor: "",
          area: ""
        }
      ]
      //   ContactPersonName: "",
      //   PhoneNumber: "",
      //   ContactSecondPersonName: "",
      //   SecondPhoneNumber: "",
      //   PanNo: "",
      //   GstNO: "",
      //   acceptedFiles: []
    };
  }
  componentDidMount() {
    //  console.log('come from props data',this.props.location.state.result)
    if (!this.props.location.state) {
      return;
    } else {
      console.log(
        "come from location props data",
        this.props.location.state.result
      );
      var data = this.props.location.state.result;
      this.setState({
        updateid: data.id,
        btntext: "Update Location",
        Locationname: data.name,
        Address: data.locality,
        State: data.stateID,
        City: data.cityID,
        Type: data.localityId,
        selectedState: { value: data.stateID, label: data.state },
        selectedCity: { value: data.cityID, label: data.city },
        selectedLocality: { value: data.localityId, label: data.locality },
        floors: data.floors
      });
      this.callcityApi(data.stateID);
      this.calltypeApi(data.cityID);
    }
    //common.formatDate(data.contract_end)
  }
  submitData = async () => {
    console.log("location submit data");
    if (this.state.btntext == "Create Location") {
      const data = {
        name: this.state.Locationname,
        Address: this.state.Address,
        State: this.state.State,
        City: this.state.City,
        locality: this.state.Type,
        floor: this.state.floors
      };
      console.log("location submit data", JSON.stringify(data));

      let validate = Validate.dynamicValidationNew("CreateLocation", data);
      if (validate !== "") {
        this.refs.Notification.openNotification("danger", validate);
        return;
      }

      let response = Api.PostData(this, Constant.PostLocation, data);
      response.then(data => {
        if (data.status == "1") {
          this.refs.Notification.openNotification("success", data.message);
          this.setState({
            Locationname: "",
            Address: "",
            State: "",
            City: "",
            Type: "",
            selectedState: "",
            selectedCity: null,
            selectedLocality: null,
            floors: [
              {
                floor: "",
                area: ""
              }
            ]
          });
        } else {
          this.refs.Notification.openNotification("danger", data.message);
        }
        // console.log("compny", data);
      });
    } else {
      const data = {
        id: this.state.updateid,
        name: this.state.Locationname,
        Address: this.state.Address,
        State: this.state.State,
        City: this.state.City,
        locality: this.state.Type,
        floor: this.state.floors
      };
      let validate = Validate.dynamicValidationNew("CreateLocation", data);
      if (validate !== "") {
        this.refs.Notification.openNotification("danger", validate);
        return;
      }

      let response = Api.PutData(this, Constant.UpdateLocation, data);
      response.then(data => {
        if (data.status == "1") {
          this.refs.Notification.openNotification("success", data.message);
          this.setState({
            Locationname: "",
            Address: "",
            State: "",
            City: "",
            Type: "",
            selectedState: "",
            selectedCity: null,
            selectedLocality: null,
            floors: [
              {
                floor: "",
                area: ""
              }
            ]
          });
          this.props.history.replace({ pathname: "/admin/cowork" });
        } else {
          this.refs.Notification.openNotification("danger", data.message);
        }
        // console.log("compny", data);
      });
    }
  };

  AddFloor() {
    let floor = this.state.floors;
    let fl = {
      floor: "",
      area: ""
    };
    floor.push(fl);

    this.setState({ floors: floor });
  }
  cancelData = () => {
    this.setState({
      Locationname: "",
      Address: "",
      State: "",
      City: "",
      Type: "",
      selectedCity: null,
      selectedLocality: null,
      selectedState: null,
      cityarray: [],
      typearray: [],
      floors: [
        {
          floor: "",
          area: ""
        }
      ]
    });
  };
  handleChangeStart = date => {
    this.setState({
      ContractStart: date
    });
  };
  handleChangeEnd = date => {
    this.setState({
      ContractEnd: date
    });
  };
  componentWillMount() {
    this.callStateApi({});
    //this.callcityApi();
    //  this.calltypeApi();
  }

  callStateApi = () => {
    let response = Api.GetData(this, Costants.GetState, {});
    response.then(data => {
      if (!data) {
        return;
      }
      for (let i = 0; i < data.data.length; i++) {
        this.state.Statearray.push({
          value: data.data[i].id,
          label: data.data[i].name
        });
      }
      // this.setState({ Statearray: data.data});
      console.log("statedata response", this.state.Statearray);
    });
  };
  handleStateChange = selectedState => {
    this.setState({ selectedState });
    this.setState({ State: selectedState.value });
    this.callcityApi(selectedState.value);
    console.log(`Option selected:`, selectedState);
  };
  calltypeApi = value => {
    this.setState({ typearray: [] });
    let response = Api.GetData(this, Costants.GetType + "/" + value, {});
    response.then(data => {
      if (!data) {
        return;
      }
      for (let i = 0; i < data.data.length; i++) {
        this.state.typearray.push({
          value: data.data[i].id,
          label: data.data[i].name
        });
      }
      // this.setState({ typearray: data.data});
      console.log("typedata response", data);
    });
  };
  handleTypeChange = selectedLocality => {
    this.setState({ selectedLocality });
    this.setState({ Type: selectedLocality.value });
    //this.callcityApi(selectedOption.value);
    console.log(`Option selected:`, selectedLocality);
  };
  callcityApi = value => {
    this.setState({ cityarray: [] });
    this.setState({ typearray: [] });
    let response = Api.GetData(this, Costants.GetCity + "/" + value, {});
    response.then(data => {
      if (!data) {
        return;
      }
      for (let i = 0; i < data.data.length; i++) {
        this.state.cityarray.push({
          value: data.data[i].id,
          label: data.data[i].name
        });
      }
      //this.setState({ cityarray: data.data});
      console.log("citydata response", data);
    });
  };
  handleCityChange = selectedCity => {
    this.setState({ selectedCity });
    this.setState({ City: selectedCity.value });
    // this.calltypeApi();
    this.calltypeApi(selectedCity.value);
    console.log(`Option city selected:`, selectedCity);
  };

  deleteFiles(index) {
    let files = this.state.acceptedFiles;
    files.splice(index, 1);

    this.setState({ acceptedFiles: files });
    console.log(this.state.acceptedFiles);
  }

  handleTextChangedropdown = msg => {
    this.setState({ Location: msg });
    console.log("dropdown value of plot " + this.state.Location);
  };
  handleTextChangedropdown2 = msg => {
    this.setState({ Plot: msg });
    console.log("dropdown value of plot " + this.state.Plot);
  };
  handleTextChange = event => {
    let val =
      event.target.value.charAt(0).toUpperCase() + event.target.value.substr(1);

    this.setState({ [event.target.name]: val });
  };

  handleFloorTextChange(event, index) {
    let floor = this.state.floors;

    let fl = floor[index];

    if (event.target.name == "Floor") {
      fl["floor"] = event.target.value;
    } else {
      fl["area"] = event.target.value;
    }

    floor.splice(index, 1, fl);

    this.setState({ floors: floor });

    // console.log(event.target.name);
    // this.setState({ [event.target.name]: event.target.value });
  }
  deleteFloor = index => {
    let value = this.state.floors;
    value.splice(index, 1);
    this.setState({ floors: value });
    //this.state.floors.splice(index,1)
  };
  render() {
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8">
              {/* Header */}
              <div className="header mt-md-5">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Pretitle */}
                      <h6 className="header-pretitle">Add Location</h6>
                      {/* Title */}
                      <h1 className="header-title">Create okurr Location</h1>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
              </div>
              {/* Form */}
              <form className="mb-4">
                {/* Project name */}
                <div className="form-group">
                  <label>Location name</label>
                  <input
                    type="text"
                    value={this.state.Locationname}
                    className="form-control capitalize"
                    name="Locationname"
                    onChange={this.handleTextChange.bind(this)}
                  />
                </div>
                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    value={this.state.Address}
                    className="form-control capitalize"
                    name="Address"
                    onChange={this.handleTextChange.bind(this)}
                  />
                </div>
                {/* <div className="form-group mt-3">
                  <label className="mb-1">Location description</label>
                  <small className="form-text text-muted">
                    Client location, floor, and area information going to be
                    here.
                  </small>
                </div> */}
                <div className="row ">
                  <div className="col-10">
                    {this.state.floors.map((floor, index) => {
                      if (index == 0) {
                        return (
                          <div className="col-12 nav row">
                            <div className="col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={floor.floor}
                                  name="Floor"
                                  onChange={event =>
                                    this.handleFloorTextChange(event, index)
                                  }
                                  className="form-control capitalize"
                                  placeholder="Floor"
                                />
                              </div>
                            </div>

                            <div className="col-md-5">
                              <div className="form-group">
                                <input
                                  type="number"
                                  value={floor.area}
                                  name="Area"
                                  onChange={event =>
                                    this.handleFloorTextChange(event, index)
                                  }
                                  className="form-control capitalize"
                                  placeholder="Area"
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <button
                                className="btn btn-white dropdown-toggle btn-block"
                                type="button"
                                onClick={this.AddFloor.bind(this)}
                              >
                                <i className="fe fe-plus" />
                              </button>
                            </div>
                          </div>
                        );
                      } else {
                        {
                          return (
                            <div className="col-12 nav row">
                              <div className="col-md-5">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    value={floor.floor}
                                    name="Floor"
                                    onChange={event =>
                                      this.handleFloorTextChange(event, index)
                                    }
                                    className="form-control capitalize"
                                    placeholder="Floor"
                                  />
                                </div>
                              </div>

                              <div className="col-md-5">
                                <div className="form-group">
                                  <input
                                    type="number"
                                    value={floor.area}
                                    name="Area"
                                    onChange={event =>
                                      this.handleFloorTextChange(event, index)
                                    }
                                    className="form-control capitalize"
                                    placeholder="Area"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <button
                                  className="btn btn-white dropdown-toggle btn-block"
                                  type="button"
                                  onClick={this.deleteFloor.bind(this, index)}
                                >
                                  <i className="fe fe-minus" />
                                </button>
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>

                  {/* <div className="col-md-2">
                    <button
                      className="btn btn-white dropdown-toggle btn-block"
                      type="button"
                      onClick={this.AddFloor.bind(this)}
                    >
                      <i className="fe fe-plus" />
                    </button>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <Select
                        placeholder="Select State"
                        value={this.state.selectedState}
                        onChange={this.handleStateChange}
                        options={this.state.Statearray}
                      />
                      {/* <Dropdown className="dropdown">
                        <Dropdown.Toggle>
                          <button
                            className="btn btn-white dropdown-toggle btn-block"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                           State
                          </button>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            
                            {this.state.Statearray.map((data,index)=>{
                                return  ( <Dropdown.Item
                                onClick={this.handleTextChangedropdown.bind(this,)}
                                >{data.name}</Dropdown.Item>
                            )})}
                        
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <Select
                        placeholder="Select City"
                        value={this.state.selectedCity}
                        onChange={this.handleCityChange}
                        options={this.state.cityarray}
                      />
                      {/* <Dropdown className="dropdown">
                        <Dropdown.Toggle>
                          <button
                            className="btn btn-white dropdown-toggle btn-block"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                           City
                          </button>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            
                            {this.state.cityarray.map((data,index)=>{
                                return  ( <Dropdown.Item
                                onClick={this.handleTextChangedropdown.bind(this,)}
                                >{data.name}</Dropdown.Item>
                            )})}
                        
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <Select
                        placeholder="Select Locality"
                        value={this.state.selectedLocality}
                        onChange={this.handleTypeChange}
                        options={this.state.typearray}
                      />
                      {/* <Dropdown className="dropdown">
                        <Dropdown.Toggle>
                          <button
                            className="btn btn-white dropdown-toggle btn-block"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                           Type
                          </button>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            
                            {this.state.typearray.map((data,index)=>{
                                return  ( <Dropdown.Item
                                onClick={this.handleTextChangedropdown.bind(this,)}
                                >{data.name}</Dropdown.Item>
                            )})}
                        
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                  </div>

                  {/* <div className="col-12 col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.State}
                        name="State"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="State"
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.City}
                        name="City"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="City"
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.Type}
                        name="Type"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="Type"
                      />
                    </div>
                  </div> */}
                </div>{" "}
                {/* / .row */}
                {/* <div className="form-group mt-3">
                  <label className="mb-1">Contract Duration</label>
                  <small className="form-text text-muted">
                    Client's contracts start and end date going to be here.
                  </small>
                </div> */}
                <div className="row">
                  <div className="col-12 col-md-6">
                    {/* Start date */}
                    {/* <div className="form-group">
                      <label>Contract Start Date</label>
                      <DatePicker
                        className="form-control capitalize"
                        selected={this.state.ContractStart}
                        onChange={this.handleChangeStart}
                      />
                     </div> */}
                  </div>
                  {/* <div className="col-12 col-md-6">
                   
                    <div className="form-group">
                      <label>Contract End Date</label>
                      <DatePicker
                        className="form-control capitalize"
                        selected={this.state.ContractEnd}
                        onChange={this.handleChangeEnd}
                      />
                      
                    </div>
                  </div> */}
                </div>{" "}
                {/* / .row */}
                {/* <div className="form-group">
                  <label>Registation Number</label>
                  <input
                    type="text"
                    value={this.state.RegistationNO}
                    name="RegistationNO"
                    onChange={this.handleTextChange.bind(this)}
                    className="form-control capitalize"
                  />
                </div> */}
                {/* Divider */}
                {/* <hr className="mt-5 mb-5" /> */}
                {/* <div className="form-group">
                  <label className="mb-1">Client Details</label>
                  <small className="form-text text-muted">
                    Add client primary and secondary contact details
                  </small>
                </div> */}
                {/* <div className="row">
                  <div className="col-12 col-md-6">
                     
                    <div className="form-group">
                     
                      <input
                        type="text"
                        value={this.state.ContactPersonName}
                        name="ContactPersonName"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="Contact person name"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                   
                    <div className="form-group">
                  
                      <input
                        type="number"
                        value={this.state.PhoneNumber}
                        name="PhoneNumber"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="Phone number"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    
                    <div className="form-group">
                     
                      <input
                        type="text"
                        value={this.state.ContactSecondPersonName}
                        name="ContactSecondPersonName"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="Secondary contact person"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                   
                    <div className="form-group">
                     
                      <input
                        type="number"
                        value={this.state.SecondPhoneNumber}
                        name="SecondPhoneNumber"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="Phone number"
                      />
                    </div>
                  </div>
                </div>{" "} */}
                {/* / .row */}
                {/* <div className="form-group">
                  <label className="mb-1">Company Information</label>
                </div> */}
                {/* <div className="row">
                  <div className="col-12 col-md-6">
                     
                    <div className="form-group">
                    
                      <input
                        type="text"
                        value={this.state.PanNo}
                        name="PanNo"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="PAN Number"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    
                    <div className="form-group">
                      
                      <input
                        type="text"
                        value={this.state.GstNO}
                        name="GstNO"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="GST Number"
                      />
                    </div>
                  </div>
                </div>{" "} */}
                <hr className="mt-4 mb-5" />
                {/* <div className="form-group">
                  <label className="mb-1">Client logo</label>
                  <small className="form-text text-muted">
                    Please use an image no larger than 1200px * 1200px.
                  </small>
                  <div className="card">
                    <div className="card-body">
                      <Dropzone
                        accept="image/*"
                        onDrop={acceptedFiles => {
                          acceptedFiles.map(file =>
                            Object.assign(file, {
                              preview: URL.createObjectURL(file)
                            })
                          );
                          console.log(acceptedFiles);
                          this.setState({ acceptedFiles });
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="fallback">
                                <div className="custom-file">
                                  <input
                                    // type="file"
                                    className="custom-file-input"
                                    id="customFileUpload"
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="customFileUpload"
                                  >
                                    Choose file
                                  </label>
                                </div>
                              </div>
                            </div>
                            <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
                              {this.state.acceptedFiles.map((k, index) => {
                                return (
                                  <li
                                    className="list-group-item px-0"
                                    key={index}
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-auto">
                                        <div className="avatar">
                                          <img
                                            className="avatar-img rounded"
                                            alt="Preview"
                                            key={k.preview}
                                            src={k.preview}
                                            // style={previewStyle}
                                          />
                                        </div>
                                      </div>
                                      <div className="col ml-n3">
                                        <h4 className="mb-1" data-dz-name>
                                          {k.name}
                                        </h4>
                                        <p
                                          className="small text-muted mb-0"
                                          data-dz-size
                                        />
                                      </div>
                                      <div className="col-auto">
                                        <a
                                          // href="#"
                                          // className="dropdown-ellipses dropdown-toggle"
                                          onClick={this.deleteFiles.bind(
                                            this,
                                            index
                                          )}
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fe fe-x-circle" />
                                        </a>
                                      </div>
                                       
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </section>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone dropzone-multiple"
                        data-toggle="dropzone"
                        data-options='{"url": "https://"}'
                      >
                        
                      </div>
                    </div>
                  </div>
                </div> 
                <hr className="mt-5 mb-5" />*/}
                <div className="row justify-content-between align-items-center">
                  <button
                    onClick={this.cancelData.bind(this)}
                    className="btn btn-light"
                    type="button"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={this.submitData.bind(this)}
                    className="btn btn-primary"
                    type="button"
                  >
                    {this.state.btntext}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
