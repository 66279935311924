import React from "react";
import PropTypes from "prop-types";

// import Modal from "react-bootstrap/lib/Modal";
import { Button, Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import * as Api from "../utils/Api";
import * as Constant from "../utils/Constants";
import Notification from "../Components/AlertNotification";

import { confirmable, createConfirmation } from "react-confirm";

class Confirmation extends React.Component {
  state = {
    roleselect: "Role Select",
    roleId: ""
  };
  callAssignroleapi = id => {
    // console.log(id)
    var data = {
      userId: this.props.proceedLabel,
      roleId: id
    };
    let response = Api.PutData(this, Constant.AssignRole, data);
    response.then(data => {
      if (data.status == "1") {
        this.props.cancel();
        this.refs.Notification.openNotification("success", data.message);
      } else {
        this.props.cancel();
        this.refs.Notification.openNotification("danger", data.message);
      }
      console.log("assign role api ", data);
    });
  };

  render() {
    const {
      okLabbel,
      proceedLabel,
      cancelLabel,
      title,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      enableEscape = true
    } = this.props;
    return (
      <div className="static-modal">
        <Notification ref="Notification" />
        <Modal
          show={show}
          onHide={dismiss}
          backdrop={enableEscape ? true : "static"}
          keyboard={enableEscape}
        >
          <Modal.Header>
            <Modal.Title>Select Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Dropdown
              // className="dropdown"
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Dropdown.Toggle style={{ width: "70%", margin: "auto" }}>
                <button
                  style={{ width: "100%" }}
                  className="btn btn-sm btn-white dropdown-toggle"
                  type="button"
                  id="bulkActionDropdown"
                  onClick={this.handleBulk}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.roleselect}
                </button>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ marginLeft: "20px", width: "60%" }}>
                {confirmation.map((data, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        this.setState({
                          roleselect: data.name,
                          roleId: data.id
                        });
                      }}
                    >
                      {data.name}
                    </Dropdown.Item>
                  );
                })}
                {/* <Dropdown.Item >Action</Dropdown.Item>
    <Dropdown.Item >Another action</Dropdown.Item>
    <Dropdown.Item >Something else</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.callAssignroleapi.bind(this, this.state.roleId)}
            >
              {cancelLabel}
            </Button>
            <Button className="button-l" bsStyle="primary" onClick={cancel}>
              {okLabbel}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.array,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  cancel: PropTypes.func, // called when cancel button is clicked.
  dismiss: PropTypes.func, // called when backdrop is clicked or escaped.
  enableEscape: PropTypes.bool
};

export function role(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "cancel",
  okLabbel = "",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    okLabbel,
    ...options
  });
}
