export const SendOtp = "users/gen-otp";
export const VerifyOtp = "users/verify-phone";
export const UserData = "users/user";
export const AddBrand = "brand/add-brand";
export const UserProfile = "users/user-profile";
export const createcompany = "company/create_company";
export const createlogourl = "upload/company-logo";
export const GetRole = "user-mgt/roleassignment";

export const Getclient = "company/all_company";
export const GetuserApprove = "user-mgt/userapprove";

export const AssignRole = "user-mgt/assignrole";
export const Getuser = "users/all_user";
export const GetLocationdrop = "building-mgt/locationname";
export const GetCompany = "company/all_company";
export const GetDashCount = "users/dashboardcount";
export const GetLocation = "building-mgt/location";
export const PostLocation = "building-mgt/create_location";
export const GetState = "location/states/101";
export const GetCity = "location/cities";
export const GetType = "location/locality";
export const GetPlot = "building-mgt/locationfloor";
export const UpdateCompany = "company/update_company";
export const UpdateLocation = "building-mgt/update_location";
export const ArchiveCompany = "company/archive_company";
export const MenuItems = "user-mgt/adminmenu";
export const ImsTickets = "ims/reports?status=";
export const GetFeeds = "social/feeds";







// export const ImagebaseUrl =
//   "https://skootr-app-assets.s3.ap-south-1.amazonaws.com/";
export const StatusArray = [
  "In Progress",
  "Accepted",
  "Rejected",
  "Assigned",
  "Resolved",
  "Closed",
  "Re-opened"
];
