import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Constants from "../utils/Constants";
import * as Api from "../utils/Api";
import Notification from "./AlertNotification";

export default class sidebar extends React.Component {
  state = {
    dropdown: false,
    sidebar: false,
    menuItems: [{ name: "Home", link: "/admin/users" }],
  };

  componentWillMount() {
    // this.callApi();
  }
  callApi = async () => {
    let response = Api.GetData(this, Constants.MenuItems, {});
    response.then((data) => {
      //console.log("data--------->",data)
      if (!data) {
        return;
      }
      if (data.data && data.data.length < 1) {
        localStorage.setItem("access", "false");
        // this.props.history.replace({
        //   pathname: "/"
        // });
        return;
      }

      let urls = [];
      for (const iterator of data.data) {
        urls.push(iterator.link);
      }

      let path = this.props.location.pathname;
      path = path.substring(1);

      if (!urls.includes(path)) {
        // localStorage.setItem("access", "false");
        // this.props.history.push("/noauth");
      }

      // console.log(this.props.location.pathname);

      this.setState({ menuItems: data.data });
    });
  };

  LogOut() {
    localStorage.removeItem("adminMail");
    // console.log(this);
    this.props.history.push("/");
  }

  handlesidebar = () => {
    this.setState({ sidebar: !this.state.sidebar });
  };

  handleDropdown = () => {
    this.setState({ dropdown: !this.state.dropdown });
  };
  render() {
    return (
      <nav
        className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
        id="sidebar"
      >
        <Notification ref="Notification" />
        <div className="container-fluid">
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded={this.state.sidebar ? true : false}
            onClick={this.handlesidebar.bind(this)}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          <a className="navbar-brand">
            <img
              src={require("./../assets/img/locol-01.b282249b.b55e6025.png")}
              className="navbar-brand-img 
  mx-auto"
              alt="..."
            />
          </a>
          {/* User (xs) */}
          <div className="navbar-user d-md-none"></div>

          <div
            className={
              this.state.sidebar
                ? "collapse navbar-collapse"
                : "expand navbar-collapse "
            }
            id="sidebarCollapse"
          >
            {/* Form */}
            {/* <form className="mt-4 mb-3 d-md-none">
              <div className="input-group input-group-rounded input-group-merge">
                <input
                  type="search"
                  className="form-control form-control-rounded form-control-prepended"
                  placeholder="Search"
                  aria-label="Search"
                />
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <span className="fe fe-search" />
                  </div>
                </div>
              </div>
            </form> */}
            {/* Heading */}
            {/* <h6 className="navbar-heading">Updates</h6> */}
            {/* Navigation */}
            <div>
              <ul className="navbar-nav">
                {this.state.menuItems.map((menu, index) => {
                  return (
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <li
                        className="nav-item"
                        onClick={this.handlesidebar.bind(this)}
                        key={index}
                      >
                        <Link className="nav-link " to={"" + menu.link}>
                          <i className={menu.icon} /> {menu.name}
                        </Link>
                      </li>
                    </div>
                  );
                })}
                {/* {access == "100" && (
                <li className="nav-item">
                  <Link className="nav-link " to="/admin">
                    <i className="fe fe-home" /> Dashboard
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link className="nav-link " to="/admin/users">
                  <i className="fe fe-users" /> Users
                </Link>
              </li>
              {access == "100" && (
                <div>
                  <li className="nav-item">
                    <Link className="nav-link " to="/admin/clients">
                      <i className="fe fe-zap" /> Clients
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/admin/cowork">
                      <i className="fe fe-globe" /> okurr Locations
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/admin/skr_employee">
                      <i className="fe fe-user-check" /> okurr Employees
                    </Link>
                  </li>
                </div>
              )} */}
              </ul>
            </div>
            {/* Divider */}
            <hr className="navbar-divider my-3" />
            {/* Heading */}
            <h6 className="navbar-heading">Admin Settings</h6>
            {/* Navigation */}
            <ul className="navbar-nav mb-md-4">
              {/* <li class="nav-item">
      <a class="nav-link " href="getting-started.html">
        <i class="fe fe-clipboard"></i> Getting started
      </a>
    </li> */}
              <li className="nav-item" onClick={this.handleDropdown}>
                <Link
                  className="nav-link"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded={this.state.dropdown ? "true" : "true "}
                  aria-controls="sidebarComponents"
                >
                  <i className="fe fe-settings" />
                  Settings
                </Link>
                <div
                  className={
                    this.state.dropdown ? "collapse show" : "collapse "
                  }
                  id="sidebarComponents"
                >
                  <ul className="nav nav-sm flex-column">
                    {/* <li className="nav-item">
                      <Link className="nav-link " to="/admin/skr_employee">
                        Profile
                      </Link>
                    </li> */}
                    <li className="nav-item" onClick={this.LogOut.bind(this)}>
                      <Link className="nav-link">
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
