import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import * as Api from "../utils/Api";
import * as common from "../utils/Common";
import * as Constant from "../utils/Constants";

export default class VerifyOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: ""
    };
  }

  LoginClkd = async () => {
    let po = {
      phone: "9958330124",
      otp: this.state.otp
    };

    let response = await Api.PostData(this, Constant.VerifyOtp, po);
    if (response.status == 1) {
      this.props.history.push("/admin");
    } else {
      alert(response.message);
    }

    console.log(response);
  };

  render() {
    return (
      <div>
        <input
          type="text"
          onChange={event => this.setState({ otp: event.target.value })}
        />
        <Button variant="primary" onClick={this.LoginClkd}>
          Verify
        </Button>
      </div>
    );
  }
}
