import React, { Component } from "react";
import Sidebar from "./Components/SideBar";
import Login from "./Pages/Login";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import Users from "./Pages/Users";
import Dashboard from "./Pages/DashBoard";
import VerifyOtp from "./Pages/VerifyOtp";
import Clients from "./Pages/Clients";
import Cowork from "./Pages/Co-working";
import Skr_Employee from "./Pages/Skooter_Employee";
import Addclient from "./Pages/AddClient";
import Addlocation from "./Pages/AddLocation";
import Reports from "./Pages/Reports";
import NotFound from "./Pages/NotFound";
import NewsPost from "./Pages/NewsPost";
import FeedList from "./Pages/FeedList";
import BuiltupUnit from "./Pages/BuiltupUnit";
import Aminities from "./Pages/Aminities"
import NoAuth from "./Pages/NoAuth";
import PrivateRoute from "./utils/PrivateRoute";

export default class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />

            {/* <Route exact path="/verifyotp" component={VerifyOtp} /> */}
            <Route
              path="/admin"
              render={({ match: { url } }) => (
                <>
                  <PrivateRoute component={Sidebar} exact />
                  <Switch>
                    <PrivateRoute
                      path={`${url}/`}
                      component={Dashboard}
                      exact
                    />
                    <PrivateRoute path={`${url}/users`} component={Users} />
                    <PrivateRoute path={`${url}/clients`} component={Clients} />
                    <PrivateRoute path={`${url}/cowork`} component={Cowork} />
                    <PrivateRoute path={`${url}/feed`} component={FeedList} />
                    <PrivateRoute path={`${url}/reports`} component={Reports} />
                    <PrivateRoute path={`${url}/amenities`} component={Aminities} />
                    <PrivateRoute path={`${url}/builtupunit`} component={BuiltupUnit} />
                    <PrivateRoute
                      path={`${url}/skr_employee`}
                      component={Skr_Employee}
                    />
                    <PrivateRoute
                      path={`${url}/addclient`}
                      component={Addclient}
                    />
                    <PrivateRoute
                      path={`${url}/addlocation`}
                      component={Addlocation}
                    />
                    <PrivateRoute path={`${url}/news_post`} component={NewsPost}/>
                    <Redirect to="/404" />
                  </Switch>
                </>
              )}
            />
            <Route path="/noauth" render={() => <NoAuth />} />
            <Route path="/404" render={() => <NotFound />} />
            <Route render={() => <NotFound />} />
          </Switch>
        </Router>
      </div>
    );
  }
}
