import React, { Component } from "react";
import { Dropdown, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone";
import "react-datepicker/dist/react-datepicker.css";
import * as Api from "../utils/Api";
import * as Constant from "../utils/Constants";
import Notification from "../Components/AlertNotification";
import * as Validate from "../utils/validatorXerox";
import * as common from "../utils/Common";
import * as Costants from "../utils/Constants";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btntext: "Create Client",
      disabled:true,
      startDate: new Date(),
      endDate: new Date(),
      Company: "",
      Location: "Select okurr Location",
      Plot: "Select Building Floor",
      Area: "",
      Seats:"",
      Email1:"",
      Email2:"",
      ContractStart: "",
      ContractEnd: "",
      RegistationNO: "",
      ContactPersonName: "",
      PhoneNumber: "",
      ContactSecondPersonName: "",
      SecondPhoneNumber: "",
      PanNo: "",
      GstNO: "",
      acceptedFiles: [],
      selectedlocationID: "",
      selectedfloorid: "",
      updateId: "",
      code: "",
      locationList: [],
      floorsList: []
    };
  }
  componentWillMount() {
    this.callLocationApi({});
  }

  callLocationApi = () => {
    let response = Api.GetData(this, Costants.GetLocationdrop, {});
    response.then(data => {
      if (!data) {
        return;
      }
      this.setState({ locationList: data.data });
      console.log("locationList response", data);
    });
  };
  callPlotApi = value => {
    let response = Api.GetData(this, Costants.GetPlot + "/" + value, {});
    response.then(data => {
      if (!data) {
        return;
      }
      this.setState({ floorsList: data.data });
      console.log("locationList response", data);
    });
  };

  componentDidMount() {
     //console.log('come from props data',this.props.location.state.result)
    if (!this.props.location.state) {
      return;
    } else {
      console.log("come from props data", this.props.location.state.result);
      var data = this.props.location.state.result;
      console.log("data---->", data);
      let primary = {};
      try {
        primary = data.primary_users ? JSON.parse(data.primary_users) : {};
      } catch (error) {}

      // let primary = JSON.parse(data.primary_users);
      //  let primary = JSON.parse(user)
      //  console.log('json user primary',user)
      // this.setState({ startDate: common.formatDate(data.contract_start) });
      // this.setState({ endDate: common.formatDate(data.contract_end) });
      //console.log(hello)
      this.setState({
        acceptedFiles: [],
        Company: data.name,
        code: data.company_code,
        ContactPersonName: primary.UserName1,
        ContactSecondPersonName: primary.UserName2,
        ContractEnd: data.contract_end,
        ContractStart: data.contract_start,
        PanNo: data.pan_num,
        GstNO: data.gst_num,
        RegistationNO: data.registration_num,
        SecondPhoneNumber: primary.UserPhone2,
        PhoneNumber: primary.UserPhone1,
        Area: data.area,
        Plot: data.floorName,
        Location: data.location,
        selectedfloorid: data.floorID,
        selectedlocationID: data.locationID,
        Seats:data.seats,
        Email1:primary.email1,
        Email2:primary.email2,
        btntext: "Update Client",
        disabled:false,
        updateId: data.id
      });
    }
  }

  reset = () => {
    this.setState({
      acceptedFiles: [],
      Company: "",
      code: "",
      ContactPersonName: "",
      ContactSecondPersonName: "",
      ContractEnd: "",
      ContractStart: "",
      PanNo: "",
      GstNO: "",
      RegistationNO: "",
      SecondPhoneNumber: "",
      PhoneNumber: "",
      Area: "",
      Plot: "Select Building Floor",
      Location: "Select okurr Location"
    });
  };

  imageUpload = () => {
    const data = {
      companyname: this.state.Company,
      code: this.state.code,
      location: this.state.selectedlocationID,
      floor: this.state.selectedfloorid,
      area: this.state.Area,
      contract_start_date: this.state.ContractStart,
      contract_end_date: this.state.ContractEnd,
      regnumber: this.state.RegistationNO,
      pri_person_name: this.state.ContactPersonName,
      pri_person_number: this.state.PhoneNumber,
      sec_person_name: this.state.ContactSecondPersonName,
      sec_person_number: this.state.SecondPhoneNumber,
      pan: this.state.PanNo,
      gst: this.state.GstNO,
      seats:this.state.Seats,
      pri_email:this.state.Email1,
      sec_email:this.state.Email2

    };
    
    console.log("dtaa-------->",data)
    let validate = Validate.dynamicValidationNew("CreateCompany", data);
    if (validate !== "") {
      this.refs.Notification.openNotification("danger", validate);
      return;
    }
    console.log("submit data------>", data);

    if (this.state.acceptedFiles.length === 0) {
      this.submitData(data);
    } else {
      var image = this.state.acceptedFiles[0];
      console.log("image api response acceptedFiles", image);
      let formdata = new FormData();
      formdata.append("file", image);

      let hello = Api.FormData(this, "upload/company-logo", formdata);
      hello.then(res => {
        console.log("image api response", res.data.filekey);
        data.logo_url = res.data.filekey;
        this.submitData(data);
      });
    }
  };

  submitData = async value => {
    console.log("data------>",value)
    if(this.state.ContractStart>this.state.ContractEnd){
      this.refs.Notification.openNotification(
        "danger",
        "contract start Date shoudn't be greater than contract end Date."
      );
      return;
    }
    if (this.state.btntext == "Create Client") {
      let response = Api.PostData(this, Constant.createcompany, value);
      response.then(data => {
        console.log("create-res----------->",data)

        if (!data) {
          this.refs.Notification.openNotification(
            "danger",
            "Something went wrong. Please try after some time."
          );
          return;
        }

        if (data.status == "1") {
          this.refs.Notification.openNotification("success", data.message);
          this.reset();
          this.props.history.push({
            pathname: "/admin/builtupunit",
            state: {
              company_id: data.company_id,
             
            }
          })
        } else {
          this.refs.Notification.openNotification("danger", data.message);
        }
        // console.log("compny", data);
      });
    } else {
      value.id = this.state.updateId;
      let response = Api.PutData(this, Constant.UpdateCompany, value);
      response.then(data => {
        if (!data) {
          this.refs.Notification.openNotification(
            "danger",
            "Something went wrong. Please try after some time."
          );
          return;
        }

        if (data.status == "1") {
          this.refs.Notification.openNotification("success", data.message);
          // this.reset();
          // this.props.history.replace({ pathname: "/admin/clients" });
        } else {
          this.refs.Notification.openNotification("danger", data.message);
        }
        console.log("compny", data);
      });
    }
  };

  cancelData = () => {
    this.props.history.push({pathname:"/admin/clients"})
    // this.setState({
    //   acceptedFiles: [],
    //   Company: "",
    //   code: "",
    //   ContactPersonName: "",
    //   ContactSecondPersonName: "",
    //   ContractEnd: "",
    //   ContractStart: "",
    //   PanNo: "",
    //   GstNO: "",
    //   RegistationNO: ""
    // });
  };
  handleChangeStart = date => {
    this.setState({
      ContractStart: common.formatDate(date)
    });
    console.log("date formate", common.formatDate(date));
  };
  handleChangeEnd = date => {
    this.setState({
      ContractEnd: common.formatDate(date)
    });
  };

  deleteFiles(index) {
    let files = this.state.acceptedFiles;
    files.splice(index, 1);

    this.setState({ acceptedFiles: files });
    console.log(this.state.acceptedFiles);
  }

  handleTextChangedropdown = msg => {
    this.setState({ floorsList: [] });
    this.setState({
      Location: msg.name,
      selectedlocationID: msg.id,
      Plot: "Select Building Floor",
      selectedfloorid: ""
    });
    console.log("dropdown value of plot " + this.state.Location);
    this.callPlotApi(msg.id);
  };

  handleTextChangedropdown2 = msg => {
    console.log("msg------->", msg);
    this.setState({ Plot: msg.name, selectedfloorid: msg.id });
    console.log("dropdown value of plot " + this.state.Plot);
  };
  handleTextChange = event => {
    let val =
      event.target.value.charAt(0).toUpperCase() + event.target.value.substr(1);
     console.log("value------->",val);
    this.setState({ [event.target.name]: val });
  };
  handleTextChangeEmail1 = event =>{
    console.log("event------",event)
    this.setState({Email1:event.target.value})
   
  }
  handleTextChangeEmail2 = event =>{
    this.setState({Email2:event.target.value})
  }
  handleTextChangeSeat = event =>{
    this.setState({Seats:event.target.value})
  }
  render() {
   //console.log("emailll-------->",this.state.ContractStart)
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8">
              {/* Header */}
              <div className="header mt-md-5">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Pretitle */}
                      <h6 className="header-pretitle">Add Client</h6>
                      {/* Title */}
                      <h1 className="header-title">Create New okurr Client</h1>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
              </div>
              {/* Form */}
              <form className="mb-4">
                {/* Project name */}
                <div className="form-group">
                  <label>Company name</label>
                  <input
                    type="text"
                    value={this.state.Company}
                    className="form-control capitalize"
                    name="Company"
                    onChange={this.handleTextChange.bind(this)}
                  />
                </div>
                <div className="form-group">
                  <label>Company code</label>
                  <input
                    type="text"
                    value={this.state.code}
                    className="form-control capitalize"
                    name="code"
                    onChange={this.handleTextChange.bind(this)}
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="mb-1">Location description</label>
                  <small className="form-text text-muted">
                    Client location, floor, and area information going to be
                    here.
                  </small>
                </div>
                <div className="row">
                  <div className="col-12 col-md-3">
                    {/* Start date */}
                    <div className="form-group">
                      {/* <label>
              okurr Location
            </label> */}
                      {/* <div className="dropdown">
                <button className="btn btn-white dropdown-toggle btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Select okurr Location
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#">PlotNo. 264, Udyog Vihar Phase 1, Udyog Vihar, Sector 20,
                    Gurugram</a>
                  <a className="dropdown-item" href="#">Unit-No-264 2nd floor, JMD Megapolis, Sohna Rd, Sector 48,
                    Gurugram</a>
                  <a className="dropdown-item" href="#">12th Floor, 9B, DLF Cyber City, DLF Phase 2, Sector 24,
                    Gurugram</a>
                  <a className="dropdown-item" href="#">Tower C, 20th Floor, Building No. 5, DLF Cyber City, DLF Phase
                    2,
                    Sector 25, Gurugram</a>
                </div>
              </div> */}
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle>
                          <button
                            className="btn btn-white dropdown-toggle btn-block"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {this.state.Location}
                          </button>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {this.state.locationList.map((k, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                onClick={this.handleTextChangedropdown.bind(
                                  this,
                                  k
                                )}
                              >
                                {k.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    {/* Start date */}
                    <div className="form-group">
                      {/* <label>
              Select Building Floor
            </label> */}
                      {/* <div className="dropdown">
                <button className="btn btn-white dropdown-toggle btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Select Building Floor
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#">Floor - 1</a>
                  <a className="dropdown-item" href="#">Floor - 2</a>
                  <a className="dropdown-item" href="#">Floor - 3</a>
                  <a className="dropdown-item" href="#">Floor - 4</a>
                  <a className="dropdown-item" href="#">Floor - 5</a>
                  <a className="dropdown-item" href="#">Floor - 6</a>
                  <a className="dropdown-item" href="#">Floor - 7</a>
                  <a className="dropdown-item" href="#">Floor - 8</a>
                  <a className="dropdown-item" href="#">Floor - 9</a>
                  <a className="dropdown-item" href="#">Floor - 10</a>
                  <a className="dropdown-item" href="#">Floor - 11</a>
                </div>
              </div> */}
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle>
                          <button
                            className="btn btn-white dropdown-toggle btn-block"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {this.state.Plot}
                          </button>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {this.state.floorsList.map((k, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                onClick={this.handleTextChangedropdown2.bind(
                                  this,
                                  k
                                )}
                              >
                                {k.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    {/* Start date */}
                    <div className="form-group">
                      {/* <label>
              Area Leased(in Sqft)
            </label> */}
                      <input
                        type="number"
                        value={this.state.Area}
                        name="Area"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="AreaLeased(inSqft)"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    {/* Start date */}
                    <div className="form-group">
                      {/* <label>
              Area Leased(in Sqft)
            </label> */}
                      <input
                        type="number"
                        value={this.state.Seats}
                        name="Number of Seats"
                        onChange={this.handleTextChangeSeat.bind(this)}
                        className="form-control capitalize"
                        placeholder="Numberofseats"
                      />
                    </div>
                  </div>

                  <div className ="col-12 col-md-2">
                  <div className ="form-group">
                  <Button varient="primary" 
                  disabled={this.state.disabled}
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/admin/builtupunit",
                      state: {
                        company_id:this.state.updateId,
                        selectedfloorid:this.state.selectedfloorid
                      }
                    })
                  }  >Builtup Unit</Button>
                  
                  </div>
                  </div>

                 
                </div>{" "}
                {/* / .row */}
                <div className="form-group mt-3">
                  <label className="mb-1">Contract Duration</label>
                  <small className="form-text text-muted">
                    Client's contracts start and end date going to be here.
                  </small>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    {/* Start date */}
                    <div className="form-group">
                      <label>Contract Start Date</label>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        className="form-control capitalize"
                        selected={Date.parse(this.state.ContractStart)}
                        onChange={this.handleChangeStart}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    {/* Start date */}
                    <div className="form-group">
                      <label>Contract End Date</label>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        className="form-control capitalize"
                        selected={Date.parse(this.state.ContractEnd)}
                        onChange={this.handleChangeEnd}
                      />
                    </div>
                  </div>
                </div>{" "}
                {/* / .row */}
                <div className="form-group">
                  <label>Registation Number</label>
                  <input
                    type="text"
                    value={this.state.RegistationNO}
                    name="RegistationNO"
                    onChange={this.handleTextChange.bind(this)}
                    className="form-control capitalize"
                  />
                </div>
                {/* Divider */}
                <hr className="mt-5 mb-5" />
                <div className="form-group">
                  <label className="mb-1">Client Details</label>
                  <small className="form-text text-muted">
                    Add client primary and secondary contact details
                  </small>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    {/* Start date */}
                    <div className="form-group">
                      {/* <label>
              Contact person
            </label> */}
                      <input
                        type="text"
                        value={this.state.ContactPersonName}
                        name="ContactPersonName"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="Contact person name"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    {/* Start date */}
                    <div className="form-group">
                      {/* <label>
              Contact person
            </label> */}
                      <input
                        type="number"
                        value={this.state.PhoneNumber}
                        name="PhoneNumber"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="Phone number"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                  <div className="form-group">
                    <input
                      type="email"
                      value={this.state.Email1}
                      name="email"
                      onChange={this.handleTextChangeEmail1.bind(this)}
                      className="form-control"
                      placeholder="Email Address"
                    />
                  </div>
                </div>

                  <div className="col-12 col-md-4">
                    {/* Start date */}
                    <div className="form-group">
                      {/* <label>
              Contact person
            </label> */}
                      <input
                        type="text"
                        value={this.state.ContactSecondPersonName}
                        name="ContactSecondPersonName"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="Secondary contact person"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    {/* Start date */}
                    <div className="form-group">
                      {/* <label>
              Contact person
            </label> */}
                      <input
                        type="number"
                        value={this.state.SecondPhoneNumber}
                        name="SecondPhoneNumber"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="Phone number"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                  <div className="form-group">
                    <input
                      type="email"
                      value={this.state.Email2}
                      name="email"
                      onChange={this.handleTextChangeEmail2.bind(this)}
                      className="form-control"
                      placeholder="Email Address"
                    />
                  </div>
                </div>
          

                </div>{" "}
                {/* / .row */}
                <div className="form-group">
                  <label className="mb-1">Company Information</label>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    {/* Start date */}
                    <div className="form-group">
                      <label>Pan Number</label>
                      <input
                        type="text"
                        value={this.state.PanNo}
                        name="PanNo"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="PAN Number"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    {/* Start date */}
                    <div className="form-group">
                      <label>GST Number</label>
                      <input
                        type="text"
                        value={this.state.GstNO}
                        name="GstNO"
                        onChange={this.handleTextChange.bind(this)}
                        className="form-control capitalize"
                        placeholder="GST Number"
                      />
                    </div>
                  </div>
                </div>{" "}
                {/* / .row */}
                {/* Divider */}
                <hr className="mt-4 mb-5" />
                {/* Project cover */}
                {/* Starting files */}
                <div className="form-group">
                  <label className="mb-1">Client logo</label>
                  <small className="form-text text-muted">
                    Please use an image no larger than 1200px * 1200px.
                  </small>
                  <div className="card">
                    <div className="card-body">
                      <Dropzone
                        accept="image/*"
                        onDrop={acceptedFiles => {
                          acceptedFiles.map(file =>
                            Object.assign(file, {
                              preview: URL.createObjectURL(file)
                            })
                          );
                          console.log(acceptedFiles);
                          this.setState({ acceptedFiles });
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="fallback">
                                <div className="custom-file">
                                  <input
                                    // type="file"
                                    className="custom-file-input"
                                    id="customFileUpload"
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="customFileUpload"
                                  >
                                    Choose file
                                  </label>
                                </div>
                              </div>
                            </div>
                            <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
                              {this.state.acceptedFiles.map((k, index) => {
                                return (
                                  <li
                                    className="list-group-item px-0"
                                    key={index}
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-auto">
                                        <div className="avatar">
                                          <img
                                            className="avatar-img rounded"
                                            alt="Preview"
                                            key={k.preview}
                                            src={k.preview}
                                            // style={previewStyle}
                                          />
                                        </div>
                                      </div>
                                      <div className="col ml-n3">
                                        <h4 className="mb-1" data-dz-name>
                                          {k.name}
                                        </h4>
                                        <p
                                          className="small text-muted mb-0"
                                          data-dz-size
                                        />
                                      </div>
                                      <div className="col-auto">
                                        <a
                                          // href="#"
                                          // className="dropdown-ellipses dropdown-toggle"
                                          onClick={this.deleteFiles.bind(
                                            this,
                                            index
                                          )}
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fe fe-x-circle" />
                                        </a>
                                      </div>
                                      {/* <div className="col-auto">
                                        <div className="dropdown">
                                          <a
                                            href="#"
                                            className="dropdown-ellipses dropdown-toggle"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <i className="fe fe-more-vertical" />
                                          </a>
                                          <div className="dropdown-menu dropdown-menu-right">
                                            <a
                                              href="#"
                                              className="dropdown-item"
                                              data-dz-remove
                                            >
                                              Remove
                                            </a>
                                          </div>
                                        </div>
                                      </div>*/}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </section>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone dropzone-multiple"
                        data-toggle="dropzone"
                        data-options='{"url": "https://"}'
                      >
                        {/* <div className="fallback">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFileUpload"
                              multiple
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileUpload"
                            >
                              Choose file
                            </label>
                          </div>
                        </div> */}
                        {/* <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
                          <li className="list-group-item px-0">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <div className="avatar">
                                  <img
                                    className="avatar-img rounded"
                                    src="..."
                                    alt="..."
                                    data-dz-thumbnail
                                  />
                                </div>
                              </div>
                              <div className="col ml-n3">
                                <h4 className="mb-1" data-dz-name>
                                  ...
                                </h4>
                                <p
                                  className="small text-muted mb-0"
                                  data-dz-size
                                />
                              </div>
                              <div className="col-auto">
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    className="dropdown-ellipses dropdown-toggle"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                      data-dz-remove
                                    >
                                      Remove
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-5 mb-5" />
                <div className="row justify-content-between align-items-center">
                  <button
                    onClick={this.cancelData.bind(this)}
                    className="btn btn-light"
                    type="button"
                  >
                    Cancel
                  </button>
                  {/* <a href="#" className="btn btn-light">
                    Cancel
                  </a> */}
                  <button
                    onClick={ this.imageUpload.bind(this)
                                       
                    }
                    //onClick={this.submitData.bind(this)}
                    className="btn btn-primary"
                    type="button"
                  >
                    {this.state.btntext} {/* Create Client */}
                  </button>
                  {/* <a href="#" className="btn btn-primary">
            Create Client
          </a> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
