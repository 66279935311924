import * as common from "../utils/Common";
const axios = require("axios");

export const base_url = "https://api.locol.partners/"


function getHeader() {
  let token = localStorage.getItem("data");
  if (token) {
    return {
      // "access-control-allow-origin" : "cors",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token, //the token is a variable which holds the token
    };
  } else {
    return {
      "Content-Type": "application/json",
    };
  }
}

function getHeaderForm() {
  let token = common.read("token");
  if (token) {
    return {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token, //the token is a variable which holds the token
    };
  } else {
    return {
      "Content-Type": "multipart/form-data",
    };
  }
}

export async function GetData(ctx, url, params) {
  try {
    const response = await axios.get(base_url + url, {
      params: params,
      headers: getHeader(),
    });
    // console.log(response);

    if (response.status == 403 || response.status == 500) {
      LogOut(ctx);
      return;
    }
    // if (response.data.status == 0) {
    //   ctx.refs.Notification.openNotification("danger", response.data.message);
    //   return;
    // }
    return response.data;
  } catch (error) {
    if (error.response.status == 403 || error.response.status == 500) {
      LogOut(ctx);
      return;
    }
    console.error(error.response);
  }
}

export async function PostData(ctx, url, params) {
  try {
    const response = await axios.post(base_url + url, params, {
      headers: getHeader(),
    });
    if (response.status == 404 || response.status == 500) {
      LogOut(ctx);
      return;
    }

    if (response.headers.token) {
      let d = {
        token: response.headers.token,
      };
      common.write(d);
    }

    // console.log(response);

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteFeed(ctx, url) {
  try {
    const response = await axios.delete(base_url + url, {
      headers: getHeader(),
    });
    if (response.status === 404) {
      LogOut(ctx);
      return;
    }

    if (response.headers.token) {
      let d = {
        token: response.headers.token,
      };
      common.write(d);
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteData(ctx, url) {
  try {
    const response = await axios.delete(base_url + url, {
      headers: getHeader(),
    });
    if (response.status === 404) {
      LogOut(ctx);
      return;
    }

    if (response.headers.token) {
      let d = {
        token: response.headers.token,
      };
      common.write(d);
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function PutData(ctx, url, params) {
  try {
    const response = await axios.put(base_url + url, params, {
      headers: getHeader(),
    });
    if (response.status == 404) {
      LogOut(ctx);
      return;
    }

    if (response.headers.token) {
      let d = {
        token: response.headers.token,
      };
      common.write(d);
    }

    console.log(response);

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function FormData(ctx, url, params) {
  try {
    const response = await axios.post(base_url + url, params, {
      // const response = await axios.post("http://192.168.1.55:3101/" + url, params,{
      headers: getHeaderForm(),
    });
    if (response.status == 404) {
      LogOut(ctx);
      return;
    }

    if (response.headers.token) {
      let d = {
        token: response.headers.token,
      };
      common.write(d);
    }

    console.log(response);

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export function LogOut(ctx) {
  localStorage.removeItem("data");
  ctx.props.history.push("/");
}

export async function getBusinesses(url){
  try {
    const response = await axios.get(base_url + url)
    return response.data
  }
  catch(err) {
    console.log(err);
  }
}

export async function gettwBusinesses(url){
  try {
    const response = await axios.get(base_url + url)
    return response.data
  }
  catch(err) {
    console.log(err);
  }
}

export async function PostApproval(url, params) {
  try {
    const response = await axios.post(base_url + url, params);

    return response.data;
  } catch (error) {
    throw error
   
  }
}


export async function SignIn(url, params) {
  try {
    const response = await axios.post(base_url + url, params);

    return response.data;
  } catch (error) {
    console.error(error);
  }
}
