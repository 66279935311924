import React, { Component } from "react";
import { DropdownButton } from "react-bootstrap";
import * as common from "../utils/Common";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Api from "../utils/Api";
import * as Costants from "../utils/Constants";
import Notification from "../Components/AlertNotification";

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
      order: "ASC",
      searchText: "",
      filteredData: [],
      responseData: [],
      bulk: false,
      selectedIndex: -1,
      all: true,
      accepted: false,
      pending: false,
      process: false,
      cancel: false,
      checkselectedIndex: -1,
      checkbox: [],
      alcheck: false,
      idselect: []
    };
  }

  // componentWillMount() {
  //   document.addEventListener("mousedown", this.handleClick, false);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClick, false);
  // }
  // handleClick = e => {
  //   if (this.node.contains(e.target)) {
  //     return;
  //   }
  //   this.setState({ accept: false, bulk: false });
  // };
  // handleclickOutside = () => {
  //   this.setState({ accept: false, bulk: false });
  // };

  sortfunc = key => {
    if (this.state.order == "ASC") {
      this.setState({ order: "DESC" });
    } else {
      this.setState({ order: "ASC" });
    }

    common.Sort(this.state.filteredData, key, this.state.order);
    // console.log(data);
  };

  searchFunc = text => {
    let d = common.Search(this.state.responseData, text);
    this.setState({ filteredData: d });
    // console.log(d);
  };

  handleBulk = () => {
    this.setState({ bulk: !this.state.bulk });
  };

  componentWillMount() {
    this.callApi({});
    // this.callRoleApi();
  }

  callApi = () => {
    let response = Api.GetData(this, Costants.GetLocation, {});
    response.then(data => {
      if (!data) {
        return;
      }
      this.setState({ filteredData: data.data, responseData: data.data });
      console.log("filterdata response", data);
    });
  };

  handlecheckbox(index, idvalue) {
    this.setState({ alcheck: false });
    console.log("index value", index);
    this.state.checkbox[index].active = !this.state.checkbox[index].active;
    this.setState({ checkbox: this.state.checkbox });
    if (this.state.checkbox[index].active == true) {
      this.state.idselect.push({ id: idvalue });
    } else {
      for (let j = 0; j < this.state.idselect.length; j++) {
        if (this.state.idselect[j].id == idvalue) {
          this.state.idselect.splice(j, 1);
        }
      }
    }
    //this.state.idselect.push({"id":idvalue})
    console.log("idselect value", this.state.idselect);
  }

  handleAllcheckbox() {
    this.setState({ alcheck: !this.state.alcheck });
    if (this.state.alcheck == false) {
      for (let i = 0; i < this.state.checkbox.length; i++) {
        this.state.checkbox[i].active = true;
        this.setState({ checkbox: this.state.checkbox });
      }
      this.state.filteredData.map(data => {
        this.state.idselect.push({ id: data.id });
      });
    } else {
      var ashish = [];
      this.setState({ idselect: ashish });
      for (let i = 0; i < this.state.checkbox.length; i++) {
        this.state.checkbox[i].active = false;
        this.setState({ checkbox: this.state.checkbox });
      }
    }
    //console.log(this.state.alcheck,'idselect handleAllcheckbox value',this.state.idselect)
  }
  handleAccept(index) {
    console.log(index);
    this.setState({ accept: !this.state.accept, selectedIndex: index });
  }
  ashish = msg => {
    this.setState({ accept: false });
    alert(msg + "hello" + this.state.selectedIndex);
  };

  display = () => {
    return this.state.filteredData.map((data, index) => {
      // this.state.checkbox.push({ active: false });
       console.log("data.floors.length",data.floors.length)
      let buildarea=0
      for(let i=0;i<data.floors.length;i++)
      {
        buildarea=parseInt(buildarea)+parseInt(data.floors[i].area)
            
      }
      return (
        <tr key={index}>
          <td className="orders-order">{data.name}</td>
          <td className="orders-product">{data.locality}</td>
          <td className="orders-total">{buildarea} Sqf</td>
          <td className="orders-total">{data.city}</td>
          <td className="goal-project">{data.state}</td>
          <span ref={node => (this.node = node)}>
            <td className="text-right">
              <Dropdown className="dropdown">
                <Dropdown.Toggle>
                  <i className="fe fe-more-vertical" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.editLocation.bind(this, data)}>
                    Edit Location
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </span>
        </tr>
      );
    });
  };
  editLocation = data => {
    console.log("addlocation message", data);
    this.props.history.push({
      pathname: "/admin/addlocation",
      state: { result: data }
    });
    //return ( <Link  to="/admin/addclient" />)
  };
  DropAlert = () => {
    alert("Hello Dashboard");
  };
  render() {
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              {/* Header */}
              <div className="header mt-md-5">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Pretitle */}
                      {/* <h6 className="header-pretitle">Clients</h6> */}
                      {/* Title */}
                      <h1 className="header-title">okurr Locations</h1>
                    </div>
                    <div className="col-auto">
                      <Link
                        className="btn btn-primary lift"
                        to="/admin/addlocation"
                      >
                        Add location
                      </Link>
                      {/* <a href="#" className="btn btn-primary lift">
                      Add location
                      </a> */}
                    </div>
                  </div>{" "}
                </div>
              </div>
              {/* Card */}
              <div
                className="card"
                data-toggle="lists"
                data-options='{"valueNames": ["orders-order", "orders-product", "orders-date", "orders-total", "orders-status", "orders-method"]}'
              >
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Search */}
                      <form className="row align-items-center">
                        <div className="col-auto pr-0">
                          <span className="fe fe-search text-muted" />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-flush search"
                            placeholder="Search"
                            onChange={event =>
                              this.searchFunc(event.target.value)
                            }
                          />
                        </div>
                      </form>
                    </div>
                    {/* <div className="col-auto">
                      
                      <Dropdown className="dropdown">
  <Dropdown.Toggle   >
  <button
                          className="btn btn-sm btn-white dropdown-toggle"
                          type="button"
                          id="bulkActionDropdown"
                          onClick={this.handleBulk}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Bulk action
                        </button>
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item onClick={this.DropAlert.bind(this)}>Action</Dropdown.Item>
    <Dropdown.Item onClick={this.DropAlert.bind(this)}>Another action</Dropdown.Item>
    <Dropdown.Item onClick={this.DropAlert.bind(this)}>Something else</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
                    </div> */}
                  </div>{" "}
                  {/* / .row */}
                </div>
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    <thead>
                      <tr>
                        {/* <th>
                          <div className="custom-control custom-checkbox table-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="ordersSelect"
                              id="ordersSelectAll"
                              checked={this.state.alcheck?true:false}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="ordersSelectAll"
                              onClick={this.handleAllcheckbox.bind(this)}
                            >
                              &nbsp;
                            </label>
                          </div>
                        </th> */}
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "name")}
                            className="text-muted sort"
                            data-sort="orders-order"
                          >
                            Location Name
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "locality")}
                            className="text-muted sort"
                            data-sort="orders-product"
                          >
                            Address
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "email")}
                            className="text-muted sort"
                            data-sort="orders-date"
                          >
                            Buildup area
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "city")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            City
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "state")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            State
                          </a>
                        </th>
                        <th>
                          <a
                            // href="#"
                            onClick={this.sortfunc.bind(this, "approval")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Action
                          </a>
                        </th>
                        {/*  <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "admin")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Admin approval
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "date")}
                            className="text-muted sort"
                            data-sort="orders-status"
                          >
                            Date
                          </a>
                        </th>
                        <th colSpan={2}>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "role")}
                            className="text-muted sort"
                            data-sort="orders-method"
                          >
                            Role
                          </a>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="list">{this.display()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* / .row */}
        </div>
      </div>
    );
  }
}
