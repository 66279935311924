import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { useAuth } from "../Context/auth";

function PrivateRoute({ component: Component, ...rest }) {
  const token = localStorage.getItem("access");
  return (
    <Route
      {...rest}
      render={
        (props) => (
          // token == "true" ? (
          <Component {...props} />
        )
        // ) : (
        //   <Redirect to={{ pathname: "/noauth" }} />
        // )
      }
    />
  );
}

export default PrivateRoute;
