import React, { Component } from "react";
import { DropdownButton } from "react-bootstrap";
import * as common from "../utils/Common";
import { Dropdown } from "react-bootstrap";
import * as Api from "../utils/Api";
import * as Costants from "../utils/Constants";
import { confirm } from "../Components/Confirmation";
import { role } from "../Components/RoleDropdown";
import Notification from "../Components/AlertNotification";
import Approval from './ApprovalModal';
import { Link } from "react-router-dom";
import Spinner from '../Components/Spinner';

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
      order: "ASC",
      searchText: "",
      filteredData: [],
      responseData: [],
      bulk: false,
      selectedIndex: -1,
      all: true,
      accepted: false,
      pending: false,
      process: false,
      cancel: false,
      checkselectedIndex: -1,
      checkbox: [],
      alcheck: false,
      idselect: [],
      role: [],
      businesses: [],
      showModal: false,
      businessUser: {},
      loading:true
    };
  }


  //gets the business list which arent arrpoved.

  async componentDidMount() {
    let adminMail = localStorage.getItem("adminMail")
    if (!adminMail) {
      this.props.history.push("/")
    }
    const businesses = await Api.getBusinesses("temp-businesses?_sort=createdAt:DESC")


    //! what is this? why is there business in the fisrt feild.


    this.setState({ businesses, filteredData: businesses, allData: businesses },()=>{
      this.setState({loader:false})
    })
    // console.log(this.state.businesses);
  }



  acceptedList = () => {
    this.setState({ filteredData: this.state.allData }, () => {
      let acceptedData = this.state.filteredData.filter(approved => approved.isApproved === true)
      this.setState({
        filteredData: acceptedData,
        businesses: acceptedData,
        all: false,
        accepted: true,
        pending: false
      })
    })

  }


  //diferent lists start over here.
  allList = () => {
    this.setState({
      businesses: this.state.allData,
      all: true,
      accepted: false,
      pending: false,
    })
  }

  //pending list comes here
  pendingList = () => {
    this.setState({ filteredData: this.state.allData }, () => {
      let pendingData = this.state.filteredData.filter(approved => approved.isApproved === false)
      console.log('pendingData: ', pendingData);




      this.setState({
        filteredData: pendingData,
        businesses: pendingData,
        all: false,
        accepted: false,
        pending: true,
      })
    })
  }

  searchFunc = (text) => {
    let upperText = text.toUpperCase()
    let filterData = this.state.filteredData.filter(name => name.user?.FirstName.toUpperCase().includes(upperText))
    this.setState({ businesses: filterData })
  };

  sortfunc = (key) => {
    if (this.state.order == "ASC") {
      this.setState({ order: "DESC" });
    } else {
      this.setState({ order: "ASC" });
    }

    common.Sort(this.state.filteredData, key, this.state.order);
    // console.log(data);
  };



  handleBulk = () => {
    this.setState({ bulk: !this.state.bulk });
  };

  // handlecheckbox(index, idvalue) {
  //   this.setState({ alcheck: false });
  //   console.log("index value", index);
  //   this.state.checkbox[index].active = !this.state.checkbox[index].active;
  //   this.setState({ checkbox: this.state.checkbox });
  //   if (this.state.checkbox[index].active == true) {
  //     this.state.idselect.push({ id: idvalue });
  //   } else {
  //     for (let j = 0; j < this.state.idselect.length; j++) {
  //       if (this.state.idselect[j].id == idvalue) {
  //         this.state.idselect.splice(j, 1);
  //       }
  //     }
  //   }
  //   console.log("idselect value", this.state.idselect);
  // }

  // handleAllcheckbox() {
  //   this.setState({ alcheck: !this.state.alcheck });
  //   if (this.state.alcheck == false) {
  //     for (let i = 0; i < this.state.checkbox.length; i++) {
  //       this.state.checkbox[i].active = true;
  //       this.setState({ checkbox: this.state.checkbox });
  //     }
  //     this.state.filteredData.map((data) => {
  //       this.state.idselect.push({ id: data.id });
  //     });
  //   } else {
  //     var ashish = [];
  //     this.setState({ idselect: ashish });
  //     for (let i = 0; i < this.state.checkbox.length; i++) {
  //       this.state.checkbox[i].active = false;
  //       this.setState({ checkbox: this.state.checkbox });
  //     }
  //   }
  //   //console.log(this.state.alcheck,'idselect handleAllcheckbox value',this.state.idselect)
  // }

  handleAccept(index) {
    console.log(index);
    this.setState({ accept: !this.state.accept, selectedIndex: index });
  }


  ashish = (msg) => {
    this.setState({ accept: false });
    alert(msg + "hello" + this.state.selectedIndex);
  };



  //does not work.
  display = () => {
    return this.state.filteredData.map((data, index) => {
      this.state.checkbox.push({ active: false });

      return (
        <tr>
          {/* <td className="orders-order">{data.id}</td> */}
          <td className="orders-product">
            <div className="avatar-group">
              <a
                //  href="profile-posts.html"
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title=""
              >
                <img
                  src={
                    data.profile_pic == ""
                      ?
                      ""
                      : ""
                    // Costants.ImagebaseUrl + data.profile_pic
                  }
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div>
            {data.f_name + " " + data.l_name}
          </td>
          <td className="orders-total">{data.email}</td>
          <td className="orders-total">{data.phone}</td>
          <td className="goal-project">
            <div className="avatar-group">
              <a
                // href={data.company ?data.company.imag:""e}
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title={data.company ? data.company.name : ""}
              >
                <img
                  // src={
                  //   data.company.logo_url == ""
                  //     ? require("../assets/img/sktrimg/co-avatar.svg")
                  //     : Costants.ImagebaseUrl + data.company.logo_url
                  // }
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div>
            {data.company ? data.company.name : ""}
          </td>
          <td className="orders-status">
            <div className="badge badge-warning">
              {data.is_approved_l1 ? "Approved" : "Pending"}
            </div>
          </td>
          {/* <td className="orders-status">
            <div className="badge badge-light">
              {data.is_approved_l2 ? "Approved" : "Pending"}
            </div>
          </td> */}
          <td className="orders-date">
            <time dateTime="2018-07-30">
              {common.NewformatDate(data.createdAt)}
            </time>
          </td>
          <td className="orders-method">{data.role}</td>
          <span ref={(node) => (this.node = node)}>
            <td className="text-right">
              <Dropdown className="dropdown">
                <Dropdown.Toggle>
                  <i
                    className="fe fe-more-vertical"
                  // onClick={this.handleAccept.bind(this,index)}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.DropAlert.bind(this, data.id)}>
                    Assign Role
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={this.DropAlertActivate.bind(this, data.id)}
                  >
                    Activate
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </span>
        </tr>
      );
    });
  };

  DropAlertActivate = (id) => {
    confirm("Are You Sure You Active This User", "OK", "Cancel").then(
      () => {
        this.callActiveuser(id);
      },
      () => {
        this.setState({
          message: "cancel",
        });
      }
    );
    // alert('Hello Dashboard');
  };


  callActiveuser = (id) => {
    var data = {
      userId: id,
    };
    let response = Api.PutData(this, Costants.GetuserApprove, data);
    response.then((data) => {
      if (data.status == "1") {
        // this.props.cancel();
        this.refs.Notification.openNotification("success", data.message);
      } else {
        // this.props.cancel();
        this.refs.Notification.openNotification("danger", data.message);
      }
      console.log("assign active role api ", data);
    });
  };
  DropAlert = (id) => {
    role(this.state.role, id, "OK", "Cancel").then(
      () => {
        this.setState({
          message: "Save",
        });
      },
      () => {
        this.setState({
          message: "cancel",
        });
      }
    );
    // alert('Hello Dashboard');
  };


  //mouts the modal in the dom
  approveModal = (item) => {
    this.setState({
      showModal: true,
      businessUser: item
    })
  }

  handleClose = (businessUser) => {
    if (businessUser === undefined) {
      this.setState({ showModal: false })
    }
    else {
      this.setState({
        showModal: false,
        businessUserId: businessUser.id
      })
    }
  }

  handleArray = (item) => {
    let str = ""
    if (item) {
      JSON.parse(item).forEach((ele,i) => {
        str += ele
        if (i + 1 !== (JSON.parse(item)).length) {
          str += ", "
        }
     })
    }
    return str
  } 


  render() {
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        {this.state.showModal ?
          <Approval
            show={this.state.showModal}
            handleClose={this.handleClose}
            businessUser={this.state.businessUser}
          />
          :
          null
        }


        <div className="container-fluid">

          <div className="row justify-content-center">
            <div className="col-12">


              {/* Header for the filters */}


              <div className="header mt-md-5">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">


                      <h1 className="header-title">Users</h1>


                    </div>
                  </div>


                  <div className="row align-items-center">
                    <div className="col">


                      <ul className="nav nav-tabs nav-overflow header-tabs">
                        <li className="nav-item">
                          <Link
                            onClick={this.allList}
                            className={
                              this.state.all ? "nav-link active" : "nav-link"
                            }
                          >
                            All
                            <span className="badge badge-pill badge-soft-secondary">
                              {/* {this.state.filteredData.length} */}
                            </span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={this.acceptedList}
                            className={
                              this.state.accepted
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Accepted
                            <span className="badge badge-pill badge-soft-secondary">
                              {/* {this.state.filteredData.length} */}
                            </span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={this.pendingList}
                            className={
                              this.state.pending
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Pending
                            <span className="badge badge-pill badge-soft-secondary">
                              {/* {this.state.filteredData.length} */}
                            </span>
                          </Link>
                        </li>
                      </ul>



                    </div>
                  </div>
                </div>
              </div>
              {this.state.loader ? <Spinner></Spinner> :
                <div className="card" data-toggle="lists">
                  {/* Search functionality */}
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        {/* Search */}
                        <form className="row align-items-center">
                          <div className="col-auto pr-0">
                            <span className="fe fe-search text-muted" />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control form-control-flush search"
                              placeholder="Search"
                              onChange={(event) =>
                                this.searchFunc(event.target.value)
                              }
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      <thead>
                        <tr>
                          <th>
                            <a
                              className="text-muted sort"
                              data-sort="orders-product"
                            >
                              User name
                            </a>
                          </th>
                          <th>
                            <a
                              //href="#"
                              onClick={this.sortfunc.bind(this, "email")}
                              className="text-muted sort"
                              data-sort="orders-date"
                            >
                              Email Address
                            </a>
                          </th>
                          <th>
                            <a
                              // href="#"
                              onClick={this.sortfunc.bind(this, "phone")}
                              className="text-muted sort"
                              data-sort="orders-total"
                            >
                              Phone number
                            </a>
                          </th>
                          <th>
                            <a
                              // href="#"
                              onClick={this.sortfunc.bind(this, "")}
                              className="text-muted sort"
                              data-sort="orders-total"
                            >
                              Company
                            </a>
                          </th>
                            <th>
                            <a
                              // href="#"
                              onClick={this.sortfunc.bind(this, "")}
                              className="text-muted sort"
                              data-sort="orders-total"
                            >
                              Category
                            </a>
                          </th>
                            <th>
                            <a
                              // href="#"
                              onClick={this.sortfunc.bind(this, "")}
                              className="text-muted sort"
                              data-sort="orders-total"
                            >
                              Subcategory
                            </a>
                          </th>

                          <th>
                            <a
                              className="text-muted sort"
                              data-sort="orders-status"
                            >
                              Date
                            </a>
                          </th>

                          <th>
                            <a
                              className="text-muted sort"
                              data-sort="orders-total"
                            >
                              Approval
                            </a>
                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state?.businesses?.length > 0 && this.state?.businesses?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item?.user?.FirstName}</td>
                                <td>{item?.user?.email?.length > 15 ? item?.user?.email.slice(0,12) + "..." : item?.user?.email}</td>
                                <td>{item?.phone}</td>
                                <td>{item?.name?.length > 30 ? item?.name.slice(0, 30) + "..." : item?.name}</td>
                                <td>{this.handleArray(item?.buss_category)?.length > 30 ? this.handleArray(item?.buss_category).slice(0,30) + "..." : this.handleArray(item?.buss_category)}</td>
                                <td>{this.handleArray(item?.Buss_subcategory)?.length > 30 ? this.handleArray(item?.Buss_subcategory).slice(0, 30) + "..." : this.handleArray(item?.Buss_subcategory)}</td>
                                <td>{item.createdAt.split("T")[0]}</td>
                                <td>
                                  <button disabled={((!item?.twbusiness?.isDisabled && item.isApproved) || this.state.businessUserId === item.id)} style={{ "border": "none", "borderRadius": "5px" }} onClick={() => this.approveModal(item)}>
                                    {item?.isApproved && item?.twbusiness?.isDisabled == true ? "Deleted" : (item?.isApproved || this.state?.businessUserId === item?.id) ? "Live" : "Pending"}
                                    {/* {(item?.isApproved || this.state?.businessUserId === item?.id) ? "Live" : "Pending"} */}
                                  </button>
                                </td>

                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>


                </div>
              }



            </div>
          </div>
        </div>
      </div>
    );
  }
}
