import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="fullscreen">
          <div className="container v-align-transform">
            <div className="row">
              <div className="col-sm-10 col-sm-offset-1">
                <div className="text-center">
                  <i className="ti-receipt icon icon-lg mb24 mb-xs-0"></i>
                  <h1 className="large">Page Not Found</h1>
                  <p>
                    The page you requested couldn't be found - this could be due
                    to a spelling error in the URL or a removed page.
                  </p>
                  <Link className="btn" to="/">
                    Go Back Home
                  </Link>
                  {/* <a className="btn" href="#">Contact Us</a> */}
                </div>
              </div>
            </div>

            <div className="embelish-icons">
              <i className="ti-help-alt"></i>
              <i className="ti-cross"></i>
              <i className="ti-support"></i>
              <i className="ti-announcement"></i>
              <i className="ti-signal"></i>
              <i className="ti-pulse"></i>
              <i className="ti-marker"></i>
              <i className="ti-pulse"></i>
              <i className="ti-alert"></i>
              <i className="ti-help-alt"></i>
              <i className="ti-alert"></i>
              <i className="ti-pulse"></i>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default NotFound;
