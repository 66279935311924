var dateFormat = require("dateformat");

function getStorage() {
  return localStorage;
}

export function read(key) {
  let data = JSON.parse(getStorage().getItem("data"));
  if (data) {
    return data[key];
  } else {
    return undefined;
  }
}

export function write(data) {
  getStorage().setItem("data", JSON.stringify(data));
}

export function remove(key) {
  getStorage().removeItem(key);
}

export function clearAll() {
  getStorage().clear();
}

export function Sort(data, key, order) {
  if (order == "ASC") {
    return data.sort((a, b) =>
      (a[key] && typeof a[key] == "String" && a[key].toLowerCase()) >
      (b[key] && typeof a[key] == "String" && b[key].toLowerCase())
        ? 1
        : -1
    );
  } else {
    return data.sort((a, b) =>
      (a[key] && typeof a[key] == "String" && a[key].toLowerCase()) <
      (b[key] && typeof a[key] == "String" && b[key].toLowerCase())
        ? 1
        : -1
    );
  }
}

export function Search(data, txt) {
  if (data.length > 0) {
    let firstitem = data[0];
    let allkeys = Object.keys(firstitem);
    const filtered = data.filter(item => {
      for (const k of allkeys) {
        if (item[k]) {
          if (
            item[k]
              .toString()
              .toLowerCase()
              .includes(txt.toLowerCase())
          ) {
            return true;
          }
        }
      }
    });
    return filtered;
  } else {
    return data;
  }
}

export function formatDate(date) {
  if (!date || date == "") {
    return "";
  }

  try {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;

    return year + "-" + month + "-" + day;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export function NewformatDate(date) {
  if (!date || date == "") {
    return "";
  }

  try {
    var d = new Date(date);
    // month = "" + (d.getMonth() + 1),
    // day = "" + d.getDate(),
    // year = d.getFullYear();

    let newd = dateFormat(d, "dd mmm , yyyy");

    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;

    return newd;
  } catch (error) {
    console.log(error);
    return "";
  }
}
