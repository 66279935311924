import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone";
import "react-datepicker/dist/react-datepicker.css";
import * as Api from "../utils/Api";
import * as Constant from "../utils/Constants";
import Notification from "../Components/AlertNotification";
import * as Validate from "../utils/validatorXerox";
import * as common from "../utils/Common";
import * as Costants from "../utils/Constants";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btntext: "Save",
      title: "",
      desc: "",
      acceptedFiles: []
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        title: this.props.location.state.title,
        desc: this.props.location.state.desc
      });
    }
  }

  imageUpload = () => {
    const data = {
      title: this.state.title,
      description: this.state.desc
    };
    let validate = Validate.dynamicValidationNew("PostFeed", data);
    if (validate !== "") {
      this.refs.Notification.openNotification("danger", validate);
      return;
    }

    var image = this.state.acceptedFiles[0];
    console.log("image api response acceptedFiles", image);
    let formdata = new FormData();
    formdata.append("file", image);

    formdata.append("title", this.state.title);
    formdata.append("desc", this.state.desc);
    let hello = Api.FormData(this, "social/feeds", formdata);
    hello.then(data => {
      if (data && data.status == "1") {
        this.refs.Notification.openNotification("success", data.message);
        this.cancelData();
      } else {
        this.refs.Notification.openNotification(
          "danger",
          "Some error occured. Please try again."
        );
      }
    });
  };

  cancelData = () => {
    this.props.history.push({pathname:"/admin/feed"})
  };

  deleteFiles(index) {
    console.log("index------->",index)
    let files = this.state.acceptedFiles;
    console.log("file------->",files)
    files.splice(index, 1);
    this.setState({ acceptedFiles: files });
  }

  handleTextChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /*----------------------------handle-update-post-------------------*/

  handleUpdateNewspost = () => {
    let id = this.props.location.state.id;

    const data = {
      title: this.state.title,
      description: this.state.desc
    };
    let validate = Validate.dynamicValidationNew("PostFeed", data);
    if (validate !== "") {
      this.refs.Notification.openNotification("danger", validate);
      return;
    }

    var image = this.state.acceptedFiles[0];
    let formdata = new FormData();
    formdata.append("file", image);

    formdata.append("title", this.state.title);
    formdata.append("desc", this.state.desc);
    let hello = Api.FormData(this, `social/update_feed/${id}`, formdata);
    hello.then(data => {
      console.log("data--->",data)
      if (data && data.status == "1") {
        this.refs.Notification.openNotification("success", data.message);
        this.cancelData()
      } else {
        this.refs.Notification.openNotification(
          "danger",
          "Some error occured. Please try again."
        );
      }
    });

    // let putData = Api.PutData(this, `social/update_feed/${id}`, data);
    // putData
    //   .then(data => {
    //     if (data && data.status == "1") {
    //       this.refs.Notification.openNotification("success", data.message);
    //     } else {
    //       this.refs.Notification.openNotification(
    //         "danger",
    //         "Some error occured. Please try again."
    //       );
    //     }
    //   })
    //   .catch(err => {
    //     console.log("update-error----->", err);
    //   });
  };

  render() {
    let id = this.props.location.state;
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8">
              {/* Header */}
              <div className="header mt-md-5">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h1 className="header-title">Post Feed</h1>
                    </div>
                  </div>
                </div>
              </div>

              <form className="mb-4">
                <div className="form-group">
                  <label> Title</label>
                  <input
                    type="text"
                    value={this.state.title}
                    className="form-control"
                    name="title"
                    onChange={this.handleTextChange.bind(this)}
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    type="text"
                    value={this.state.desc}
                    className="form-control"
                    name="desc"
                    cols="40"
                    rows="5"
                    onChange={this.handleTextChange.bind(this)}
                  />
                </div>

                <div className="form-group">
                  <label className="mb-1">Image</label>
                  <small className="form-text text-muted">
                    Please use an image no larger than 1200px * 1200px.
                  </small>
                  <div className="card">
                    <div className="card-body">
                      <Dropzone
                        accept="image/*"
                        onDrop={acceptedFiles => {
                          acceptedFiles.map(file =>
                            Object.assign(file, {
                              preview: URL.createObjectURL(file)
                            })
                          );
                          console.log(acceptedFiles);
                          this.setState({ acceptedFiles });
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="fallback">
                                <div className="custom-file">
                                  <input
                                    // type="file"
                                    className="custom-file-input"
                                    id="customFileUpload"
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="customFileUpload"
                                  >
                                    Choose file
                                  </label>
                                </div>
                              </div>
                            </div>
                            <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
                              {this.state.acceptedFiles.map((k, index) => {
                                return (
                                 
                                  <li
                                    className="list-group-item px-0"
                                    key={index}
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-auto">
                                        <div className="avatar">
                                          <img
                                            className="avatar-img rounded"
                                            alt="Preview"
                                            key={k.preview}
                                            src={k.preview}
                                            // style={previewStyle}
                                          />
                                        </div>
                                      </div>
                                      <div className="col ml-n3">
                                        <h4 className="mb-1" data-dz-name>
                                          {k.name}
                                        </h4>
                                        <p
                                          className="small text-muted mb-0"
                                          data-dz-size
                                        />
                                      </div>
                                      <div className="col-auto">
                                        <a
                                          // href="#"
                                          // className="dropdown-ellipses dropdown-toggle"
                                          onClick={this.deleteFiles.bind(
                                            this,
                                            index
                                          )}
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fe fe-x-circle" />
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </section>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone dropzone-multiple"
                        data-toggle="dropzone"
                        data-options='{"url": "https://"}'
                      ></div>
                    </div>
                  </div>
                </div>
                <hr className="mt-5 mb-5" />
                <div className="row justify-content-between align-items-center">
                  <button
                    onClick={this.cancelData.bind(this)}
                    className="btn btn-light"
                    type="button"
                  >
                    Cancel
                  </button>

                  {id ? (
                    <button
                      onClick={this.handleUpdateNewspost}
                      className="btn btn-primary"
                      type="button"
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      onClick={this.imageUpload.bind(this)}
                      //onClick={this.submitData.bind(this)}
                      className="btn btn-primary"
                      type="button"
                    >
                      {this.state.btntext} {/* Create Client */}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
