import React, { Component } from "react";
import { Dropdown, Card, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone";
import "react-datepicker/dist/react-datepicker.css";
import * as Api from "../utils/Api";
import * as Constant from "../utils/Constants";
import Notification from "../Components/AlertNotification";
import * as Validate from "../utils/validatorXerox";
import * as common from "../utils/Common";
import * as Costants from "../utils/Constants";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btntext: "Save",
      title: "",
      desc: "",
      id: "",
      feedList: [],
      acceptedFiles: []
    };
  }
  async componentDidMount() {
    this.callApi();
  }

  callApi = () => {
    let response = Api.GetData(this, Costants.GetFeeds, {});
    response.then(data => {
      if (!data) {
        return;
      }
      this.setState({ feedList: data.data ? data.data : [] });
      console.log(data);
    });
  };

  //   imageUpload = () => {
  //     const data = {
  //       title: this.state.title,
  //       description: this.state.desc
  //     };
  //     let validate = Validate.dynamicValidationNew("PostFeed", data);
  //     if (validate !== "") {
  //       this.refs.Notification.openNotification("danger", validate);
  //       return;
  //     }

  //     var image = this.state.acceptedFiles[0];
  //     console.log("image api response acceptedFiles", image);
  //     let formdata = new FormData();
  //     formdata.append("file", image);

  //     formdata.append("title", this.state.title);
  //     formdata.append("desc", this.state.desc);
  //     let hello = Api.FormData(this, "social/feeds", formdata);
  //     hello.then(data => {
  //       if (data && data.status == "1") {
  //         this.refs.Notification.openNotification("success", data.message);
  //         this.cancelData();
  //       } else {
  //         this.refs.Notification.openNotification(
  //           "danger",
  //           "Some error occured. Please try again."
  //         );
  //       }
  //     });
  //   };

  cancelData = () => {
    this.setState({
      title: "",
      desc: "",
      acceptedFiles: []
    });
  };

  deleteFiles(index) {
    let files = this.state.acceptedFiles;
    files.splice(index, 1);
    this.setState({ acceptedFiles: files });
  }

  handleTextChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /*--------------------deleteApi---------------------------------------------------*/

  handleDelete = (e, id) => {
    e.preventDefault();

    let deleteFeed = Api.deleteFeed(this, `social/delete_feed/${id}`);
    deleteFeed
      .then(res => {
        console.log("delete-res------>", res);
        this.callApi();
      })
      .catch(error => {
        console.log("delete-error------>", error);
      });
  };

  render() {
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">
              <div className="header mt-md-5">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h1 className="header-title"> Feeds</h1>
                    </div>
                    <div className="col-auto">
                      <Link
                        className="btn btn-primary lift"
                        to="/admin/news_post"
                      >
                        Add Feed
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <ul style={{ listStyleType: "none" }}>
                  {this.state.feedList.map((value, key) => {
                    return (
                      <div key={key}>
                        <Card>
                          {value.feedMedia.length > 0 && (
                            <Card.Img
                              style={{ height: "200px", objectFit: "contain" }}
                              // src={
                              //   "https://skootr-app-assets.s3.ap-south-1.amazonaws.com/" +
                              //   value.feedMedia[0].url
                              // }
                            />
                          )}
                          <Card.Body>
                            <Card.Title>{value.title}</Card.Title>
                            <Card.Text>{value.desc}</Card.Text>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end"
                              }}
                            >
                              <Button
                                variant="primary"
                                onClick={() =>
                                  this.props.history.push({
                                    pathname: "/admin/news_post",
                                    state: {
                                      id: value.id,
                                      title: value.title,
                                      desc: value.desc
                                    }
                                  })
                                }
                              >
                                Update
                              </Button>
                              <Button
                                style={{ marginLeft: "20px" }}
                                variant="secondary"
                                onClick={e => this.handleDelete(e, value.id)}
                              >
                                Delete
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
