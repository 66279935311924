import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import * as Api from "../utils/Api";
import "./style.css";
import Spinner from '../Components/Spinner';
import axios from 'axios';


export default function Approval(props) {

    const [selectShow, setSelectShow] = useState(false)
    const [selectUL, setSelectUL] = useState(false)
    const [inputBusiness, setInputBusiness] = useState({ name : "", Address : "" })
    const [twBusinesses, setTwBusinesses] = useState([])
    const [twBusinessesSearch, setTwBusinessesSearch] = useState([])
    const [typeId, setTypeId] = useState("")
    const [userForApproval, setUserForApproval] = useState({})
    const [Approval, setApproval] = useState({})
    const [loading,setLoading]=useState(false)
    const [error,setError]=useState({
        type:'',
        message:''
    })

    useEffect(() => {
    
        fetchData()
        setTypeId("1")
    },[])

//fetches unlibked businees only
    async function fetchData(){
        const twBusinesses = await Api.gettwBusinesses("twbusinesses/unlinked")
        setTwBusinesses(twBusinesses)
        setTwBusinessesSearch(twBusinesses)
    }

    const radioSelect = (e) => {
        setTypeId(e.target.id)
        if(e.target.id === "2"){
            setSelectShow(true)
        }else{
            setSelectShow(false)
        }
    }

    // const reactiveBusinesss = async (twbusinessID) => {
    //     const reactiveUser = 
    //     // axios.put()
    // }

    const onInputBusinessChange = (e) => {
        let searchKey = e.target.value.toUpperCase()
        setInputBusiness({ name : e.target.value })
        let searchedList = twBusinessesSearch?.filter(list => list.name.toUpperCase().includes(searchKey))
        console.log(searchedList);
        if(searchedList.length>0){
            setSelectUL(true)
            setTwBusinesses(searchedList)
        }
        if(searchKey === ""){
            setSelectUL(false)
        }
        
    }

    const businessCategory = (item) => {
        setUserForApproval(item)
        setInputBusiness({name: item.name, address: item.Address })
        setSelectUL(false)
    }

    //approves applications on the basis of the type (New , Existing)
    const submitApproval = async () => {

        let getBusinessesDetails = props?.businessUser?.twbusiness;
        let userID = props?.businessUser?.user?._id

        if(getBusinessesDetails?.isDisabled){
            try {
                await axios.put(`${Api.base_url}twbusinesses/reactiveBusinesss/${getBusinessesDetails._id}`,{
                    data : "business has been live again"
                });
                await axios.put(`${Api.base_url}activeUser?userId=${userID}`,{
                    data : "user is active again"
                });
                setTimeout(()=>{
                    props.handleClose(props.businessUser)
                    window.location.reload()
                }, 1000)
            } catch (error) {
                setError({type:'Server',message:'Could not live business'})
            }
            return;
        }else {
           try{
            let obj = {
            
                tempID : props.businessUser.user?.temp_business,
                type : parseInt(typeId),
                bussinessID: parseInt(typeId) == 2 ? userForApproval.id : ""
            }
                    
            const Approval = await Api.PostApproval("temp-businesses/approve", obj)
            setApproval(Approval)
            setTimeout(()=>{
                props.handleClose(props.businessUser)
            }, 3000)
           }catch (err){

             setError({type:'Server',message:'Could not Approve, Try finding in existing business'})

           }
        }
    }

    return (
      <>
  
        <Modal show={props.show} onHide={props.handleClose}>
          <div className="text-center p-4">
            {
               error.type.length>0&&error.message.length>0?<div className="text-danger">
               {error.message}
           </div>:''
            }



              {
                  Approval.status === 1 
                  ?
                  <div className="text-success" style={{ "fontSize": "x-large"}}>
                      Business Approved Successfully!
                  </div>
                  :
                    <>
                    <div>
                        <h3>
                            {!props?.businessUser?.twbusiness?.isDisabled ? <>
                            Approve {`${props.businessUser.user?.FirstName} ${props.businessUser.user?.LastName}`} as?
                            </> :
                            <>
                            Reactivate {`${props.businessUser.user?.FirstName} ${props.businessUser.user?.LastName}`}'s Business Account?
                            </>
                            }
                            </h3>
                    </div>
                    <div>
                        {!props?.businessUser?.twbusiness?.isDisabled ? <>
                        <input type="radio" defaultChecked style={{"margin": "5px 10px 10px 35px"}} onClick={radioSelect} id="1" name="Business" className='margin-left-zero'/>New Business
                        <input type="radio" style={{"margin": "5px 10px 10px 35px"}} onClick={radioSelect} id="2" name="Business" className='for-mobile-space'/>Existing Business
                        </> : <>
                        <input type="radio" defaultChecked style={{"margin": "5px 10px 10px 35px"}} id="3" name="Business" className='for-mobile-space'/>Reactivate User
                        </>}
                    </div>
                    
                    {
                        selectShow ?
                        
                            <div className="position-relative col-sm-8 mx-auto p-0">
                                <input type="text" onChange={(e)=>onInputBusinessChange(e)} value={inputBusiness.name} className="form-control"/>
                                
                                {
                                    selectUL ?
                                    <div className="drop-box-product">
                                    <ul className="m-0 p-0 list-product">
                                    {
                                        twBusinesses?.map((item, index) => {
                                            return (
                                                <li key={index} onClick={()=>businessCategory(item)}>
                                                    <span style={{"cursor": "pointer"}}>{item.name}</span><br/>
                                                    <span style={{"cursor": "pointer"}}>{item.Address}</span>
                                                </li>
                                            )
                                        })
                                    }
                                    </ul>
                                    </div>
                                    :
                                    null
                                }
                                
                            </div>
                        :
                        null
                    }
                    
                    <div>
                        <button className="btn-responsive" style={{ "border": "1px solid black", "width": "14%", "height": "33px", "borderRadius": "5px", "marginTop": "15px" }} onClick={submitApproval}>Approve</button>
                    </div>
                    </>
                }
          </div>
        </Modal>
      </>
    );
  }