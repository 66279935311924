import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";

import "../src/assets/css/theme.min.css";
// import "../src/assets/css/theme-dark.min.css";

import "../src/assets/fonts/feather/feather.css";

// import "../src/assets/libs/flatpickr/dist/flatpickr.min.css";
// import "../src/assets/libs/quill/dist/quill.core.css";
import "../src/assets/libs/select2/dist/css/select2.min.css";
// import "../src/assets/libs/highlight.js/styles/vs2015.css";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-notification-alert/dist/animate.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../src/assets/css/custom.css";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
