import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import * as Api from "../utils/Api";
import * as Constant from "../utils/Constants";
import * as common from "../utils/Common";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { all } from "q";
import Select from "react-select";
import DatePicker from "react-datepicker";
import * as Validate from "../utils/validatorXerox";
import Notification from "../Components/AlertNotification";
import "react-datepicker/dist/react-datepicker.css";

const { ExportCSVButton } = CSVExport;

export default class Reports extends Component {
  columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      }
    },
    {
      dataField: "cat.name",
      text: "Category",
      sort: true
    },
    {
      dataField: "subCat.name",
      text: "Sub Category",
      sort: true
    },
    {
      dataField: "comment",
      text: "comment",
      sort: true
    },
    {
      dataField: "user.f_name",
      text: "User",
      sort: true
    },
    {
      dataField: "company.name",
      text: "Company",
      sort: true
    },
    {
      dataField: "assignee.f_name",
      text: "Assignee",
      sort: true
    },
    {
      dataField: "status",
      text: "status",
      sort: true
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true
    }
  ];

  type = [
    {
      label: "All",
      value: "0,1,2,3,4,5"
    },
    {
      label: "In Progress",
      value: "0"
    },
    {
      label: "Accepted",
      value: "1"
    },
    {
      label: "Rejected",
      value: "2"
    },
    {
      label: "Assigned",
      value: "3"
    },
    {
      label: "Resolved",
      value: "4"
    },
    {
      label: "Closed",
      value: "5"
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedtype: "",
      imsData: [],
      Start: "",
      End: ""
    };
  }

  handleChangeStart = date => {
    this.setState({
      Start: common.formatDate(date)
    });
    console.log("date formate", common.formatDate(date));
  };
  handleChangeEnd = date => {
    this.setState({
      End: common.formatDate(date)
    });
  };

  callApi = value => {
    let arr = {
      startdate: this.state.Start,
      endDate: this.state.End,
      status: value
    };

    let validate = Validate.dynamicValidationNew("Reports", arr);
    if (validate !== "") {
      this.refs.Notification.openNotification("danger", validate);
      return;
    }

    let data = {
      startdate: this.state.Start,
      endDate: this.state.End
    };

    let response = Api.GetData(this, Constant.ImsTickets + value, data);
    response.then(data => {
      if (!data) {
        return;
      }
      if (data.data.length > 0) {
        this.setState({ imsData: data.data });
      } else {
        this.refs.Notification.openNotification("info", "No Data");
        this.setState({ imsData: data.data });
      }
    });
  };

  handleStateChange = selectedState => {
    this.setState({ selectedtype: selectedState });
  };

  SearchClicked = () => {
    this.callApi(this.state.selectedtype.value);
  };

  render() {
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="header mt-md-5">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h1 className="header-title">Incidents</h1>
                    </div>
                    <div className="col-auto"></div>
                  </div>
                </div>
              </div>

              <div className="card" data-toggle="lists">
                <div className="table-responsive">
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.imsData}
                    columns={this.columns}
                    exportCSV={{ onlyExportFiltered: false, exportAll: all }}
                  >
                    {props => (
                      <div>
                        <div className="row justify-content-center">
                          <div className="col-12 col-lg-3 col-md-12 col-sm-6 col-xs-12">
                            <div className="col-12">
                              <label>From</label>
                              <DatePicker
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                selected={Date.parse(this.state.Start)}
                                onChange={this.handleChangeStart}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="col-12">
                              <label>To</label>
                              <DatePicker
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                selected={Date.parse(this.state.End)}
                                onChange={this.handleChangeEnd}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="col-12">
                              <label>Status</label>
                              <Select
                                placeholder="Select Status"
                                value={this.state.selectedtype}
                                onChange={this.handleStateChange}
                                options={this.type}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-1 col-md-12 col-sm-12 col-xs-12">
                            <button
                              type="button"
                              className="search-btn"
                              onClick={this.SearchClicked.bind(this)}
                            >
                              Find
                            </button>
                          </div>
                          <div className="col-12 col-lg-2 col-md-12 col-sm-12 col-xs-12 export-csv-btn">
                            <ExportCSVButton {...props.csvProps}>
                              Export CSV
                            </ExportCSVButton>
                          </div>
                        </div>

                        <hr />

                        <BootstrapTable
                          {...props.baseProps}
                          hover
                          condensed
                          noDataIndication="No Data"
                          pagination={
                            this.state.imsData.length > 0
                              ? paginationFactory()
                              : null
                          }
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  {/* <ToolkitProvider
                    keyField="id"
                    data={this.products}
                    columns={this.columns}
                    pagination={paginationFactory()}
                    exportCSV
                  >
                    {props => (
                      <div>
                        <ExportCSVButton {...props.csvProps}>
                          Export CSV!!
                        </ExportCSVButton>
                        <hr /> */}
                  {/* <BootstrapTable
                    keyField="id"
                    data={this.products}
                    columns={this.columns}
                    pagination={paginationFactory()}
                  /> */}
                  {/* </div>
                    )}
                  </ToolkitProvider> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
