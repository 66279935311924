import React, { Component } from "react";

import { Bar, Chart } from "react-chartjs-2";
import * as Api from "../utils/Api";
import * as common from "../utils/Common";
import * as Costants from "../utils/Constants";
import { Dropdown } from "react-bootstrap";
import Notification from "../Components/AlertNotification";

import { Link } from "react-router-dom";

import * as ChartExt from "../utils/Chart";

export default class Dashboard extends React.Component {
  componentWillMount() {
    ChartExt.CustomizeChart();
    this.callApi();
    this.callcompanyApi();
    this.callCountApi();
    this.GetMonthsArray();
  }

  state = {
    accept: false,
    dashcount: {},
    monthArray: [],
    compdatasets: {},
    chartdata: {
      labels: [],
      datasets: [],
    },
    selectedIndex: -1,
    users: [],
    company: [],
  };

  GetMonthsArray = () => {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var today = new Date();
    var arr = [];
    for (var i = 11; i >= 0; i -= 1) {
      var d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      var month =
        monthNames[d.getMonth()] +
        "-" +
        d.getFullYear().toString().substr(2, 2);
      arr.push(month);
    }
    this.setState({ monthArray: arr });
  };

  callApi = async () => {
    // let response = Api.GetData(this, Costants.Getuser, { limit: 5 });
    // response.then(data => {
    //   if (!data) {
    //     return;
    //   }
    //   this.setState({ users: data.data });
    // });
  };

  ConfigureGraphData(data) {
    let userDataArray = [];
    let compDataArray = [];
    this.state.monthArray.forEach((month) => {
      for (const user_d of data.userbymonth) {
        if (user_d.mon == month) {
          userDataArray.push(user_d.total);
          return;
        }
      }
      userDataArray.push(0);
    });

    this.state.monthArray.forEach((month) => {
      for (const comp_d of data.companybymonth) {
        if (comp_d.mon == month) {
          compDataArray.push(comp_d.total);
          return;
        }
      }
      compDataArray.push(0);
    });

    let userdataSets = {
      label: "Users",
      backgroundColor: "#2C7BE5",
      isChecked: true,
      // borderWidth: 1,
      // hoverBackgroundColor: "rgba(255,99,132,0.4)",
      // hoverBorderColor: "rgba(255,99,132,1)",
      data: userDataArray,
    };

    let chartdata = this.state.chartdata;
    chartdata.labels = this.state.monthArray;
    chartdata.datasets.push(userdataSets);

    let compdatasets = {
      label: "Company",
      backgroundColor: "#d2ddec",
      isChecked: true,
      // borderWidth: 1,
      // hoverBackgroundColor: "rgba(255,99,132,0.4)",
      // hoverBorderColor: "rgba(255,99,132,1)",
      data: compDataArray,
    };

    this.setState({ chartdata, compdatasets });

    // console.log(userDataArray);
    // console.log(compDataArray);
  }

  callCountApi = async () => {
    let response = Api.GetData(this, Costants.GetDashCount, {});
    response.then((data) => {
      if (!data) {
        return;
      }
      this.setState({ dashcount: data.data });
      this.ConfigureGraphData(data.data);
    });
  };

  callcompanyApi = async () => {
    let response = Api.GetData(this, Costants.GetCompany, { limit: 5 });
    response.then((data) => {
      if (!data) {
        return;
      }
      console.log(data);
      this.setState({ company: data.data });
    });
  };
  dispalyUser = () => {
    return this.state.users.map((data, index) => {
      //  this.state.checkbox.push({"active":false})

      return (
        <tr key={index}>
          <td className="goal-project">
            <div className="avatar-group">
              <a
                // href="profile-posts.html"
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title="Alaya Turnbull"
              >
                <img
                  src=""
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div>
            {data.f_name + " " + data.l_name}
          </td>
          <td className="goal-project">{data.phone}</td>
          <td className="goal-pro <div className= 'row align-items-center'>ject">
            {data.email}
          </td>
          <td className="goal-date">
            <time dateTime="2018-10-24">
              {common.NewformatDate(data.createdAt)}
            </time>
          </td>
          <td className="goal-status">
            <span className="text-success"></span> {data.is_approved_l2}
          </td>
        </tr>
      );
    });
  };

  displayCompany = () => {
    return this.state.company.map((data, index) => {
      //  this.state.checkbox.push({"active":false})

      let user = {};
      try {
        user = JSON.parse(data.primary_users);
      } catch (error) {}

      return (
        <tr key={index}>
          <td className="goal-project">
            <div className="avatar-group">
              <a
                // href="profile-posts.html"
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title="Alaya Turnbull"
              >
                <img
                  src=""
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div>
            {data.name}
          </td>
          <td className="goal-project">{user.UserPhone1}</td>
          <td className="goal-pro <div className= 'row align-items-center'>ject">
            {common.NewformatDate(data.contract_start) +
              ":" +
              common.NewformatDate(data.contract_end)}
          </td>
        </tr>
      );
    });
  };

  handleAccept = (index) => {
    this.setState({ accept: !this.state.accept, selectedIndex: index });
    // this.setState({ accept: !this.state.accept });
  };
  ashish = (msg) => {
    this.setState({ accept: false });
    alert(msg + "hello");
  };
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });

    if (this.state.isChecked) {
      let d = this.state.chartdata;
      let arr = d.datasets;
      arr.splice(1, 1);
      d.datasets = arr;

      this.setState({
        data: d,
      });
    } else {
      // let newdata = {
      //   label: "Affiliate",
      //   backgroundColor: "#d2ddec",
      //   isChecked: true,
      //   // borderWidth: 1,
      //   // hoverBackgroundColor: "rgba(255,99,132,0.4)",
      //   // hoverBorderColor: "rgba(255,99,132,1)",
      //   data: [15, 10, 20, 12, 7, 0, 8, 16, 18, 16, 10, 22]
      // };

      let d = this.state.chartdata;
      let arr = d.datasets;
      arr.push(this.state.compdatasets);
      d.datasets = arr;

      this.setState({
        data: d,
      });
    }
  };
  render() {
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        <div className="header">
          <div className="container-fluid">
            {/* Body */}
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                  <h6 className="header-pretitle">Overview</h6>

                  <h1 className="header-title">Dashboard</h1>
                </div>
                {/* <div className="col-auto">
                  <a href="#!" className="btn btn-primary lift">
                    Create Client
                  </a>
                </div> */}
              </div>
            </div>
            {/* / .header-body */}
          </div>
        </div>
        <div className="container-fluid">
          {/* Row 1st */}
          <div className="row">
            <div className="col-12 col-xl-12">
              {/* Orders */}
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      Title
                      <h4 className="card-header-title">Monthly Activity</h4>
                    </div>
                    <div className="col-auto mr-n3">
                      {/* Caption */}
                      <span className="text-muted">Compare:</span>
                    </div>
                    <div className="col-auto">
                      {/* Switch */}
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="cardToggle"
                          data-toggle="chart"
                          data-target="#ordersChart"
                          checked={this.state.isChecked}
                          onChange={this.toggleChange}
                        />

                        <label
                          className="custom-control-label"
                          htmlFor="cardToggle"
                        />
                      </div>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
                <Bar
                  data={this.state.chartdata}
                  width={100}
                  height={38}
                  options={ChartExt.options}
                />
                {/* <div className="card-body">
                  <div className="chart">
                    <canvas id="ordersChart" className="chart-canvas" />
                  </div>
                </div> */}
              </div>
            </div>
          </div>{" "}
          {/* / .row */}
          <div className="row">
            <div className="col-12 col-lg-6 col-xl">
              {/* Card */}
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Title */}
                      <h6 className="card-title text-uppercase text-muted mb-2">
                        Total Clients
                      </h6>
                      {/* Heading */}
                      <span className="h2 mb-0">
                        {" "}
                        {this.state.dashcount.totalClients}
                      </span>
                      {/* Badge */}
                      {/* <span className="badge badge-soft-success mt-n1">
                        +3.5%
                      </span> */}
                    </div>
                    <div className="col-auto">
                      {/* Icon */}
                      <span className="h1 fe fe-zap text-muted mb-0" />
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl">
              {/* Card */}
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Title */}
                      <h6 className="card-title text-uppercase text-muted mb-2">
                        Total Users
                      </h6>
                      {/* Heading */}
                      <span className="h2 mb-0">
                        {this.state.dashcount.totalUsers}
                      </span>
                    </div>
                    <div className="col-auto">
                      {/* Icon */}
                      <span className="h1 fe fe-users text-muted mb-0" />
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl">
              {/* Card */}
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Title */}
                      <h6 className="card-title text-uppercase text-muted mb-2">
                        Total Space
                      </h6>
                      <div className="row align-items-center no-gutters">
                        <div className="col-auto">
                          {/* Heading */}
                          <span className="h2 mr-2 mb-0">
                            {this.state.dashcount.totalArea} Sqf
                          </span>
                        </div>
                        {/* <div className="col">
                          <div className="progress progress-sm">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "65%" }}
                              aria-valuenow={65}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div> */}
                      </div>{" "}
                      {/* / .row */}
                    </div>
                    <div className="col-auto">
                      {/* Icon */}
                      <span className="h2 fe fe-layout text-muted mb-0" />
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="card-title text-uppercase text-muted mb-2">
                        Revenue
                      </h6>

                      <span className="h2 mb-0">₹2.5Crore</span>
                    </div>
                    <div className="col-auto">
                      
                      <div className="chart chart-sparkline">
                        <canvas className="chart-canvas" id="sparklineChart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* / .row */}
          <div className="row">
            <div className="col-12 col-xl-6">
              {/* Goals */}
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Title */}
                      <h4 className="card-header-title">New Users</h4>
                    </div>
                    <div className="col-auto">
                      {/* Button */}
                      <Link className="btn btn-sm btn-white" to="/admin/users">
                        View All
                      </Link>
                      {/* <a href="#!" className="btn btn-sm btn-white">
                        View All
                      </a> */}
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
                <div
                  className="table-responsive mb-0"
                  data-toggle="lists"
                  data-options='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
                >
                  <table className="table table-sm table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>
                          <a
                            // href="#"
                            className="text-muted sort"
                            data-sort="goal-project"
                          >
                            User Name
                          </a>
                        </th>
                        <th>
                          <a
                            // href="#"
                            className="text-muted sort"
                            data-sort="goal-project"
                          >
                            Phone
                          </a>
                        </th>
                        <th>
                          <a
                            // href="#"
                            className="text-muted sort"
                            data-sort="goal-project"
                          >
                            Email
                          </a>
                        </th>
                        <th>
                          <a
                            // href="#"
                            className="text-muted sort"
                            data-sort="goal-date"
                          >
                            Date
                          </a>
                        </th>
                        <th>
                          <a
                            // href="#"
                            className="text-muted sort"
                            data-sort="goal-status"
                          >
                            Status
                          </a>
                        </th>
                        {/* <th className="text-right">Actions</th> */}
                        <th />
                      </tr>
                    </thead>
                    <tbody className="list">
                      {this.dispalyUser()}
                      {/* table row end */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6">
              {/* Goals */}
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Title */}
                      <h4 className="card-header-title">New Clients</h4>
                    </div>
                    <div className="col-auto">
                      {/* Button */}
                      <Link
                        className="btn btn-sm btn-white"
                        to="/admin/clients"
                      >
                        View All
                      </Link>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
                <div
                  className="table-responsive mb-0"
                  data-toggle="lists"
                  data-options='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
                >
                  <table className="table table-sm table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>
                          <a
                            // href="#"
                            className="text-muted sort"
                            data-sort="goal-project"
                          >
                            Client Name
                          </a>
                        </th>
                        <th>
                          <a
                            // href="#"
                            className="text-muted sort"
                            data-sort="goal-project"
                          >
                            Contact Number
                          </a>
                        </th>
                        <th>
                          <a
                            // href="#"
                            className="text-muted sort"
                            data-sort="goal-date"
                          >
                            Contract Date
                          </a>
                        </th>
                        {/* <th>
                          <a
                            // href="#"
                            className="text-muted sort"
                            data-sort="goal-status"
                          >
                            Status
                          </a>
                        </th>
                        <th className="text-right">Actions</th>
                        <th /> */}
                      </tr>
                    </thead>
                    <tbody className="list">{this.displayCompany()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* / .row */}
        </div>
      </div>
    );
  }
}
