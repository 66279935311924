import React, { Component } from "react";
import { DropdownButton } from "react-bootstrap";
import * as common from "../utils/Common";
import { Dropdown } from "react-bootstrap";
import * as Api from "../utils/Api";
import * as Costants from "../utils/Constants";
import { confirm } from "../Components/Confirmation";
import { role } from "../Components/RoleDropdown";
import Notification from "../Components/AlertNotification";

export default class Skooter_Employee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
      order: "ASC",
      searchText: "",
      filteredData: [],
      responseData: [],
      bulk: false,
      selectedIndex: -1,
      all: true,
      accepted: false,
      pending: false,
      process: false,
      cancel: false,
      checkselectedIndex: -1,
      checkbox: [],
      alcheck: false,
      idselect: [],
      role: [],
    };

    this.state.filteredData = this.state.responseData;
  }

  // componentWillMount() {
  //   document.addEventListener("mousedown", this.handleClick, false);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClick, false);
  // }
  // handleClick = e => {
  //   if (this.node.contains(e.target)) {
  //     return;
  //   }
  //   this.setState({ accept: false, bulk: false });
  // };
  // handleclickOutside = () => {
  //   this.setState({ accept: false, bulk: false });
  // };

  sortfunc = (key) => {
    if (this.state.order == "ASC") {
      this.setState({ order: "DESC" });
    } else {
      this.setState({ order: "ASC" });
    }

    common.Sort(this.state.filteredData, key, this.state.order);
    // console.log(data);
  };

  searchFunc = (text) => {
    let d = common.Search(this.state.responseData, text);
    this.setState({ filteredData: d });
    // console.log(d);
  };

  handleBulk = () => {
    this.setState({ bulk: !this.state.bulk });
  };

  handlecheckbox(index, idvalue) {
    this.setState({ alcheck: false });
    console.log("index value", index);
    this.state.checkbox[index].active = !this.state.checkbox[index].active;
    this.setState({ checkbox: this.state.checkbox });
    if (this.state.checkbox[index].active == true) {
      this.state.idselect.push({ id: idvalue });
    } else {
      for (let j = 0; j < this.state.idselect.length; j++) {
        if (this.state.idselect[j].id == idvalue) {
          this.state.idselect.splice(j, 1);
        }
      }
    }
    console.log("idselect value", this.state.idselect);
  }

  handleAllcheckbox() {
    this.setState({ alcheck: !this.state.alcheck });
    if (this.state.alcheck == false) {
      for (let i = 0; i < this.state.checkbox.length; i++) {
        this.state.checkbox[i].active = true;
        this.setState({ checkbox: this.state.checkbox });
      }
      this.state.filteredData.map((data) => {
        this.state.idselect.push({ id: data.id });
      });
    } else {
      var ashish = [];
      this.setState({ idselect: ashish });
      for (let i = 0; i < this.state.checkbox.length; i++) {
        this.state.checkbox[i].active = false;
        this.setState({ checkbox: this.state.checkbox });
      }
    }
    //console.log(this.state.alcheck,'idselect handleAllcheckbox value',this.state.idselect)
  }

  handleAccept(index) {
    console.log(index);
    this.setState({ accept: !this.state.accept, selectedIndex: index });
  }
  ashish = (msg) => {
    this.setState({ accept: false });
    alert(msg + "hello" + this.state.selectedIndex);
  };

  componentWillMount() {
    // this.callApi({});
    // this.callRoleApi();
  }

  callApi = (params) => {
    // params.type = "1";
    // let response = Api.GetData(this, Costants.Getuser, params);
    // response.then(data => {
    //   if (!data) {
    //     return;
    //   }
    //   this.setState({ filteredData: data.data, responseData: data.data });
    //   console.log("filterdata response", data);
    // });
  };

  callRoleApi = () => {
    // let response = Api.GetData(this, Costants.GetRole, {});
    // response.then(data => {
    //   if (!data) {
    //     return;
    //   }
    //   this.setState({ role: data.data });
    //   console.log("getrole api", data);
    // });
  };
  display = () => {
    return this.state.filteredData.map((data, index) => {
      this.state.checkbox.push({ active: false });

      return (
        <tr>
          {/* <td className="orders-order">{data.id}</td> */}
          <td className="orders-product">
            <div className="avatar-group">
              <a
                //  href="profile-posts.html"
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title=""
              >
                <img
                  src=""
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div>
            {data.f_name + " " + data.l_name}
          </td>
          <td className="orders-total">{data.email}</td>
          <td className="orders-total">{data.phone}</td>
          <td className="goal-project">
            <div className="avatar-group">
              <a
                // href={data.company ?data.company.imag:""e}
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title={data.company ? data.company.name : ""}
              >
                <img
                  src=""
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div>
            {data.company ? data.company.name : ""}
          </td>
          <td className="orders-status">
            <div className="badge badge-warning">
              {data.is_approved_l1 ? "Approved" : "Pending"}
            </div>
          </td>
          {/* <td className="orders-status">
            <div className="badge badge-light">
              {data.is_approved_l2 ? "Approved" : "Pending"}
            </div>
          </td> */}
          <td className="orders-date">
            <time dateTime="2018-07-30">
              {common.NewformatDate(data.createdAt)}
            </time>
          </td>
          <td className="orders-method">{data.role}</td>
          <span ref={(node) => (this.node = node)}>
            <td className="text-right">
              <Dropdown className="dropdown">
                <Dropdown.Toggle>
                  <i
                    className="fe fe-more-vertical"
                    // onClick={this.handleAccept.bind(this,index)}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.DropAlert.bind(this, data.id)}>
                    Assign Role
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={this.DropAlertActivate.bind(this, data.id)}
                  >
                    Activate
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </span>
        </tr>
      );
    });
  };
  DropAlertActivate = (id) => {
    confirm("Are You Sure You Active This User", "OK", "Cancel").then(
      () => {
        this.callActiveuser(id);
      },
      () => {
        this.setState({
          message: "cancel",
        });
      }
    );
    // alert('Hello Dashboard');
  };
  callActiveuser = (id) => {
    var data = {
      userId: id,
    };
    let response = Api.PutData(this, Costants.GetuserApprove, data);
    response.then((data) => {
      if (data.status == "1") {
        // this.props.cancel();
        this.refs.Notification.openNotification("success", data.message);
      } else {
        // this.props.cancel();
        this.refs.Notification.openNotification("danger", data.message);
      }
      console.log("assign active role api ", data);
    });
  };
  DropAlert = (id) => {
    role(this.state.role, id, "OK", "Cancel").then(
      () => {
        this.setState({
          message: "Save",
        });
      },
      () => {
        this.setState({
          message: "cancel",
        });
      }
    );
    // alert('Hello Dashboard');
  };
  render() {
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              {/* Header */}
              <div className="header mt-md-5">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Pretitle */}
                      {/* <h6 className="header-pretitle">Clients</h6> */}
                      {/* Title */}
                      <h1 className="header-title">Users</h1>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Nav */}
                      <ul className="nav nav-tabs nav-overflow header-tabs">
                        <li className="nav-item">
                          <a
                            href="#!"
                            onClick={() => {
                              {
                                this.callApi({});
                              }
                              this.setState({
                                all: true,
                                accepted: false,
                                pending: false,
                                process: false,
                                cancel: false,
                              });
                            }}
                            className={
                              this.state.all ? "nav-link active" : "nav-link"
                            }
                          >
                            All{" "}
                            <span className="badge badge-pill badge-soft-secondary">
                              {/* {this.state.filteredData.length} */}
                            </span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#!"
                            onClick={() => {
                              {
                                this.callApi({ status: "Approved" });
                              }
                              this.setState({
                                all: false,
                                accepted: true,
                                pending: false,
                                process: false,
                                cancel: false,
                              });
                            }}
                            className={
                              this.state.accepted
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Accepted{" "}
                            <span className="badge badge-pill badge-soft-secondary">
                              {/* {this.state.filteredData.length} */}
                            </span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#!"
                            onClick={() => {
                              {
                                this.callApi({ status: "Pending" });
                              }
                              this.setState({
                                all: false,
                                accepted: false,
                                pending: true,
                                process: false,
                                cancel: false,
                              });
                            }}
                            className={
                              this.state.pending
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Pending{" "}
                            <span className="badge badge-pill badge-soft-secondary">
                              {/* {this.state.filteredData.length} */}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card" data-toggle="lists">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Search */}
                      <form className="row align-items-center">
                        <div className="col-auto pr-0">
                          <span className="fe fe-search text-muted" />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-flush search"
                            placeholder="Search"
                            onChange={(event) =>
                              this.searchFunc(event.target.value)
                            }
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    <thead>
                      <tr>
                        {/* <th>
                          <a
                            //href="#"
                            onClick={this.sortfunc.bind(this, "id")}
                            className="text-muted sort"
                            data-sort="orders-order"
                          >
                            ID
                          </a>
                        </th> */}
                        <th>
                          <a
                            // href="#"
                            onClick={this.sortfunc.bind(this, "f_name")}
                            className="text-muted sort"
                            data-sort="orders-product"
                          >
                            User name
                          </a>
                        </th>
                        <th>
                          <a
                            //href="#"
                            onClick={this.sortfunc.bind(this, "email")}
                            className="text-muted sort"
                            data-sort="orders-date"
                          >
                            Email Address
                          </a>
                        </th>
                        <th>
                          <a
                            // href="#"
                            onClick={this.sortfunc.bind(this, "phone")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Phone number
                          </a>
                        </th>
                        <th>
                          <a
                            // href="#"
                            onClick={this.sortfunc.bind(this, "")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Company
                          </a>
                        </th>
                        <th>
                          <a
                            //href="#"
                            onClick={this.sortfunc.bind(this, "is_approved_l1")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Approval
                          </a>
                        </th>
                        {/* <th>
                          <a
                            // href="#"
                            onClick={this.sortfunc.bind(this, "is_approved_l2")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Admin approval
                          </a>
                        </th> */}
                        <th>
                          <a
                            //href="#"
                            onClick={this.sortfunc.bind(this, "date")}
                            className="text-muted sort"
                            data-sort="orders-status"
                          >
                            Date
                          </a>
                        </th>
                        <th>
                          <a
                            //  href="#"
                            onClick={this.sortfunc.bind(this, "role")}
                            className="text-muted sort"
                            data-sort="orders-status"
                          >
                            Role
                          </a>
                        </th>
                        <th colSpan={2}>
                          <a
                            //href="#"
                            // onClick={this.sortfunc.bind(this, "role")}
                            className="text-muted "
                            data-sort="orders-method"
                          >
                            Action
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list">{this.display()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
