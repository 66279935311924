import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Dropdown, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import * as Api from "../utils/Api";
import * as common from "../utils/Common";
import Notification from "../Components/AlertNotification";
export default class Aminities extends Component {
  state = {
    amenities: [],
    Type: "",
    select_area: "",
    Capacity: "",
    desc: "",
    name: "",
    UnitType: [],
    Type_id: "",
    id: "",
    acceptedFiles: [],
    array: [],
    Type_id: "",
    areaid: "",
    toggle: false,
    isactive: "",
    interval: "1"
  };

  componentWillMount() {
    console.log("dtaa------->", this.props.location.state.data);
    var finalvalue = this.props.location.state.data.amanities.map(val => {
      return val.id;
    });
    console.log("===========", finalvalue);
    this.GetShowData();
    this.GetAmenityDetail();
    if (this.props.location.state) {
      this.setState({
        Type: this.props.location.state.data.builtupUnit_type.type_name,
        desc:
          this.props.location.state.data.detail === null
            ? ""
            : this.props.location.state.data.detail.description,
        name:
          this.props.location.state.data.detail === null
            ? ""
            : this.props.location.state.data.detail.name,
        select_area: this.props.location.state.data.area,
        Capacity:
          this.props.location.state.data.detail === null
            ? " "
            : this.props.location.state.data.detail.capacity,

        interval:
          this.props.location.state.data.detail === null
            ? "1"
            : this.props.location.state.data.detail.room_type,

        id: this.props.location.state.data.id,
        Type_id: this.props.location.state.data.builtupUnit_type.id,
        areaid: this.props.location.state.data.builtupAreaId,
        array: finalvalue
        // desc: this.props.location.state.desc
      });
    }
  }
  /*------------------------------unit type api------------*/
  GetShowData = () => {
    let response = Api.GetData(this, `builtupunit/unit_types`, {});
    response.then(data => {
      if (!data) {
        return;
      }
      //console.log("unit type---->", data.data);
      this.setState({ UnitType: data.data });
    });
  };

  deleteFiles = index => {
    //console.log("index------->", index);
    let files = this.state.acceptedFiles;
    //console.log("file------->", files);
    files.splice(index, 1);
    this.setState({ acceptedFiles: files });
  };

  handlePopupDrop = e => {
    //  console.log("dropdown --------->", e);
    this.setState({ Type: e.type_name, Type_id: e.id });
  };
  handleToggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };
  handleTextChange = e => {
    this.setState({ name: e.target.value });
  };
  handleChange_input(e) {
    this.setState({ select_area: e.target.value });
  }
  handleChange_input_cap(e) {
    this.setState({ Capacity: e.target.value });
  }
  handleTextChange_desc(e) {
    this.setState({ desc: e.target.value });
  }
  /*------------------------amenitydetailApi------------------------*/
  GetAmenityDetail = () => {
    let response = Api.GetData(this, `builtupunit/getAmenitess`, {});
    response.then(data => {
      console.log("amenity--------->", data.data);
      if (!data) {
        return;
      }
      this.setState({ amenities: data.data });
    });
  };
  /*-----------------------updateApi-----------------------*/
  updatetype = () => {
    const data = {
      area: this.state.select_area,
      builtupUnitTypeId: this.state.Type_id,
      name: this.state.name,
      description: this.state.desc,
      builtupAreaId: this.state.areaid,
      amanityId: this.state.array,
      capacity: this.state.Capacity,
      room_type: this.state.interval
    };
    console.log("data----------->", data);

    let response = Api.PutData(
      this,
      `builtupunit/update_builtupUnit/${this.state.id}`,
      data
    );
    response.then(data => {
      if (data.status == 1) {
        this.refs.Notification.openNotification("success", data.message);
        this.props.history.push({
          pathname: "/admin/builtupunit",
          state: {
            company_id: this.props.location.state.company_id,
            location: this.props.location.state.location
          }
        });
      } else {
        this.refs.Notification.openNotification("danger", data.message);
      }
    });
  };
  /*--------------------------------------canceldata-------------------------*/
  cancelData = () => {
    this.props.history.push({
      pathname: "/admin/builtupunit",
      state: {
        location: this.props.location.state.location,
        company_id: this.props.location.state.company_id
      }
    });
  };

  /*----------------------------handleSelect-----------------------*/

  handleSelectActiveButtons = (e, data) => {
    e.preventDefault();
    console.log("data-----", data);
    let arr = this.state.array;

    if (arr.includes(data.id)) {
      arr = arr.filter(val => val != data.id);
    } else {
      arr.push(data.id);
    }
    console.log("data-----", arr);

    this.setState({ array: arr });

    // let filterData = this.state.amenities.filter(value => value.id === data.id);
    // filterData.map(v => {
    //   v.is_active = v.is_active === 0 ? 1 : 0;
    // });
    // console.log("filter",data)
    // // let a = this.state.array.concat(data);
    // // this.setState({ array: a });

    // this.setState({ amenities: this.state.amenities });
  };
  handleRadioChecked = e => {
    this.setState({ interval: e });
  };

  render() {
    //console.log("object", this.state.interval);
    return (
      <React.Fragment>
        <div className="main-content">
          <div className="container-fluid">
            <Notification ref="Notification" />
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 ">
                <div className="header mt-md-5">
                  <div className="header-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h1 className="header-title">BuiltUnit Update</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <form className="mb-4">
                  <div className="form-group">
                    <label>Type name</label>
                    <Dropdown className="dropdown" onClick={this.handleToggle}>
                      <Dropdown.Toggle
                        style={{
                          width: "100%"
                        }}
                      >
                        <button
                          checked={this.state.interval ? "true" : ""}
                          className="btn btn-white dropdown-toggle btn-block"
                          type="button"
                          style={{ textAlign: "left" }}
                        >
                          {this.state.Type}
                          <span style={{ marginLeft: "88%" }}>
                            {<i className="fe fe-chevron-down"></i>}
                          </span>
                        </button>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {this.state.UnitType.map((k, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              onClick={this.handlePopupDrop.bind(this, k)}
                            >
                              {k.type_name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="form-group"></div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label> select area</label>
                        <input
                          type="number"
                          value={this.state.select_area}
                          className="form-control"
                          name="type name"
                          pattern="[0-9]*"
                          onChange={this.handleChange_input.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label> Enter Capacity</label>
                        <input
                          type="number"
                          value={this.state.Capacity}
                          className="form-control"
                          name="type name"
                          pattern="[0-9]*"
                          onChange={this.handleChange_input_cap.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      value={this.state.name}
                      className="form-control"
                      name=" name"
                      onChange={this.handleTextChange.bind(this)}
                    />
                  </div>
                  <hr className="mt-3 mb-3" />
                  <label>Unit Type</label>
                  <hr className="mt-3 mb-4" />
                  <div className="form-group">
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="interval"
                      checked={this.state.interval == "1" ? true : ""}
                      onChange={e => this.setState({ interval: "1" })}
                    />
                    <span style={{ marginLeft: "10px" }}>Private</span>

                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="interval"
                      checked={this.state.interval == "2" ? true : ""}
                      onChange={e => this.setState({ interval: "2" })}
                    />
                    <span style={{ marginLeft: "10px" }}>Public</span>
                  </div>
                  <hr className="mt-3 mb-5" />
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      type="text"
                      value={this.state.desc}
                      className="form-control"
                      name="desc"
                      cols="40"
                      rows="5"
                      onChange={this.handleTextChange_desc.bind(this)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1">Image</label>
                    <small className="form-text text-muted">
                      Please use an image no larger than 1200px * 1200px.
                    </small>
                    <div className="card">
                      <div className="card-body">
                        <Dropzone
                          accept="image/*"
                          onDrop={acceptedFiles => {
                            acceptedFiles.map(file =>
                              Object.assign(file, {
                                preview: URL.createObjectURL(file)
                              })
                            );
                            console.log(acceptedFiles);
                            this.setState({ acceptedFiles });
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="fallback">
                                  <div className="custom-file">
                                    <input
                                      // type="file"
                                      className="custom-file-input"
                                      id="customFileUpload"
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="customFileUpload"
                                    >
                                      Choose file
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
                                {this.state.acceptedFiles.map((k, index) => {
                                  return (
                                    <li
                                      className="list-group-item px-0"
                                      key={index}
                                    >
                                      <div className="row align-items-center">
                                        <div className="col-auto">
                                          <div className="avatar">
                                            <img
                                              className="avatar-img rounded"
                                              alt="Preview"
                                              key={k.preview}
                                              src={k.preview}
                                              // style={previewStyle}
                                            />
                                          </div>
                                        </div>
                                        <div className="col ml-n3">
                                          <h4 className="mb-1" data-dz-name>
                                            {k.name}
                                          </h4>
                                          <p
                                            className="small text-muted mb-0"
                                            data-dz-size
                                          />
                                        </div>
                                        <div className="col-auto">
                                          <a
                                            // href="#"
                                            // className="dropdown-ellipses dropdown-toggle"
                                            onClick={this.deleteFiles.bind(
                                              this,
                                              index
                                            )}
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <i className="fe fe-x-circle" />
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </section>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone dropzone-multiple"
                          data-toggle="dropzone"
                          data-options='{"url": "https://"}'
                        ></div>
                      </div>
                    </div>
                  </div>
                </form>
                <hr className="mt-3 mb-5" />
                <label>Amenities</label>
                <hr className="mt-3 mb-5" />
                {this.state.amenities.map((val, index) => {
                  return (
                    <button
                      className={
                        this.state.array.includes(val.id)
                          ? " btn-primary  btn-rounded btn-sm"
                          : "btn-secondary btn-rounde btn-sm"
                      }
                      type="button"
                      onClick={e => this.handleSelectActiveButtons(e, val)}
                      key={index}
                      style={{
                        width: "110px",
                        marginLeft: "10px",
                        padding: "inherit",
                        height: "30px"
                      }}
                    >
                      {val.name}
                    </button>
                  );
                })}

                <hr className="mt-5 mb-5" />
                <div className="row justify-content-between align-items-center">
                  <div>
                    <button
                      onClick={this.cancelData.bind(this)}
                      className="btn btn-light"
                      type="button"
                      style={{ marginBottom: "30px" }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "flex-end"
                  // }}
                  >
                    <button
                      onClick={this.updatetype}
                      className="btn btn-primary"
                      type="button"
                      style={{ marginBottom: "30px" }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
