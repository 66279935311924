import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";

export default class AlertNotification extends React.Component {
  openNotification(type, msg) {
    let options = {
      place: "tr",
      message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 4
    };
    this.refs.Notification.notificationAlert(options);
  }
  render() {
    return (
      <div>
        <NotificationAlert ref="Notification" />
      </div>
    );
  }
}
