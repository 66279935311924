import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import * as common from "../utils/Common";
import * as Constant from "../utils/Constants";
import * as Api from "../utils/Api";
// import Spinner from "../Components/Spinner";
import { Modal } from "react-bootstrap";
import Notification from "../Components/AlertNotification";
import * as Validate from "../utils/validatorXerox";
import { confirm } from "../Components/Confirmation";
import Spinner from '../Components/Spinner';
import {Formik, Field} from 'formik';
import * as yup from 'yup';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerStatus: false,
      Email: "",
      password: "",
      show: false,
      loader:false
    };
  }


  componentDidMount() {
    if (!this.props.location.state) {
      return;
    } else {
      this.refs.Notification.openNotification(
        "danger",
        this.props.location.state.msg
      );
    }
  }


  handleClose = () => {
    this.setState({ show: false });
  };



  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}



//this validation is through the roof junky.
  LoginClkd = async (e) => {
    e.preventDefault();
    let po = {
      email: this.state.Email,
      password: this.state.password
    };
    if(this.validateEmail(po.email) && po.password.length > 0){
      this.setState({loader:true})
      let signIn = await Api.SignIn('login/admin', po)
      console.log('signIn: ', signIn);

      if(signIn.id !== undefined && signIn.id !== "")
      {
        
        this.setState({loader:false},()=>{
          localStorage.setItem("adminMail", signIn.email)
          this.props.history.push("/admin/users");   
        })
        
      } else  this.setState({ errorMessage : "Invalid Credentials*"})
      
    } else  this.setState({ errorMessage : " Invalid Credentails"})
      
    
    this.setState({loader:false})
  };

  handleOnClick() {
    confirm("Are you sure?", "Save", "Reset").then(
      () => {
        this.setState({
          message: "Save"
        });
      },
      () => {
        this.setState({
          message: "cancel"
        });
      }
    );
  }

  SubmitOtP = async () => {
    let po = {
      phone: this.state.number,
      otp: this.state.otp
    };

    let response = await Api.PostData(this, Constant.VerifyOtp, po);
    if (response.status == 1) {
      this.setState({ show: false });
      this.callMenuApi();
    } else {
      alert(response.message);
    }
  };

  callMenuApi = async () => {
    let response = Api.GetData(this, Constant.MenuItems, {});
    response.then(data => {
      if (!data) {
        return;
      }
      if (data.data && data.data.length < 1) {
        // localStorage.setItem("access", "false");
        this.props.history.replace({
          pathname: "/noauth"
        });
        return;
      }
      localStorage.setItem("access", "true");
      this.props.history.push(data.data[0].link);
    });
  };

  render() {
    return (
      <div>
        <Notification ref="Notification" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-5 col-xl-4 my-5">
              {/* Heading */}
              <h1 className="display-4 text-center mb-3">Sign in</h1>
              {/* Subheading */}
              <p className="text-muted text-center mb-5">
                Access Locol dashboard.
              </p>
             
              <div>
                <span style={{textAlign:"center",color: "red" }}>{this.state.errorMessage}</span>
              </div>

             
              <form>
                {/* Email address */}
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    onChange={event => {
                      this.setState({ Email: event.target.value });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="**********"
                    onChange={event => {
                      this.setState({ password: event.target.value });
                    }}
                  />
                </div>

              
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary mb-3"
                  onClick={this.LoginClkd.bind(this)}
                  disabled={this.state.loader?true:false}
                >
                  {this.state.loader?<Spinner/>:'Login'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
