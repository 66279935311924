import React, { Component } from "react";
import { DropdownButton } from "react-bootstrap";
import * as common from "../utils/Common";
import { Link, Redirect } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import * as Api from "../utils/Api";
import * as Costants from "../utils/Constants";
import { constants } from "buffer";
import { confirm } from "../Components/Confirmation";
import Notification from "../Components/AlertNotification";

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
      order: "ASC",
      searchText: "",
      filteredData: [],
      responseData: [],
      bulk: false,
      selectedIndex: -1,
      all: true,
      accepted: false,
      pending: false,
      process: false,
      cancel: false,
      checkselectedIndex: -1,
      checkbox: [],
      alcheck: false,
      idselect: []
    };

    this.state.filteredData = this.state.responseData;
  }

  // componentWillMount() {
  //   document.addEventListener("mousedown", this.handleClick, false);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClick, false);
  // }
  // handleClick = e => {
  //   if (this.node.contains(e.target)) {
  //     return;
  //   }
  //   this.setState({ accept: false, bulk: false });
  // };
  // handleclickOutside = () => {
  //   this.setState({ accept: false, bulk: false });
  // };
  componentWillMount() {
    this.callApi();
  }

  callApi = () => {
    this.setState({ idselect: [], checkbox: [] });
    let response = Api.GetData(this, Costants.Getclient, {});
    response.then(data => {
      if (!data) {
        return;
      }
      this.setState({ filteredData: data.data, responseData: data.data });
      console.log("filterdata response", data);
      this.display();
    });
  };
  sortfunc = key => {
    if (this.state.order == "ASC") {
      this.setState({ order: "DESC" });
    } else {
      this.setState({ order: "ASC" });
    }

    common.Sort(this.state.filteredData, key, this.state.order);
    // console.log(data);
  };

  searchFunc = text => {
    let d = common.Search(this.state.responseData, text);
    this.setState({ filteredData: d });
    // console.log(d);
  };

  handleBulk = () => {
    this.setState({ bulk: !this.state.bulk });
  };

  handlecheckbox(index, idvalue) {
    this.setState({ alcheck: false });
    console.log("index value", index);
    this.state.checkbox[index].active = !this.state.checkbox[index].active;
    this.setState({ checkbox: this.state.checkbox });
    if (this.state.checkbox[index].active == true) {
      this.state.idselect.push({ id: idvalue });
    } else {
      for (let j = 0; j < this.state.idselect.length; j++) {
        if (this.state.idselect[j].id == idvalue) {
          this.state.idselect.splice(j, 1);
        }
      }
    }
    console.log("idselect value", this.state.idselect);
  }

  handleAllcheckbox() {
    this.setState({ alcheck: !this.state.alcheck });
    if (this.state.alcheck == false) {
      for (let i = 0; i < this.state.checkbox.length; i++) {
        this.state.checkbox[i].active = true;
        this.setState({ checkbox: this.state.checkbox });
      }
      this.state.filteredData.map(data => {
        this.state.idselect.push({ id: data.id });
      });
    } else {
      var ashish = [];
      this.setState({ idselect: ashish });
      for (let i = 0; i < this.state.checkbox.length; i++) {
        this.state.checkbox[i].active = false;
        this.setState({ checkbox: this.state.checkbox });
      }
    }
    //console.log(this.state.alcheck,'idselect handleAllcheckbox value',this.state.idselect)
  }

  handleAccept(index) {
    console.log(index);
    this.setState({ accept: !this.state.accept, selectedIndex: index });
  }
  ashish = msg => {
    this.setState({ accept: false });
    alert(msg + "hello" + this.state.selectedIndex);
  };
  editClient = data => {

    console.log("editclient message", data);
    this.props.history.push({
      pathname: "/admin/addclient",
      state: { result: data }
    });
    //return ( <Link  to="/admin/addclient" />)
  };
  builtup = data =>{
  console.log("builtup data----->",data)
     this.props.history.push({
       pathname: "/admin/builtupunit",
       state:{company_id:data.id,location:data.location}
     })
    
  }
  Archivecompany = () => {
    if (this.state.idselect.length == 0) {
      this.refs.Notification.openNotification(
        "danger",
        "No select any company"
      );
    } else {
      let data = {
        id: this.state.idselect
      };
      let response = Api.PutData(this, Costants.ArchiveCompany, data);
      response.then(data => {
        if (!data) {
          this.refs.Notification.openNotification(
            "danger",
            "Something went wrong. Please try after some time."
          );
          return;
        }

        if (data.status == "1") {
          this.refs.Notification.openNotification("success", data.message);
          this.callApi();
          // this.setState({});
          //this.setState({ state: this.state });
          //this.props.history.replace({ pathname: "/admin/clients" });
        } else {
          this.refs.Notification.openNotification("danger", data.message);
        }
        console.log("compny", data);
      });
    }
  };
  display = () => {
    return this.state.filteredData.map((data, index) => {
      this.state.checkbox.push({ active: false });
      //  console.log(this.state.checkbox)

      let primary_users = {};
      try {
        primary_users = data.primary_users
          ? JSON.parse(data.primary_users)
          : {};
      } catch (error) {}

      // var primary_users = data.primary_users
      //   ? JSON.parse(data.primary_users)
      //   : {};

      return (
        <tr key={index}>
          <td>
            <div className="custom-control custom-checkbox table-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="ordersSelect"
                checked={this.state.checkbox[index].active ? true : false}
                id="ordersSelectOne"
              />
              <label
                className="custom-control-label"
                onClick={this.handlecheckbox.bind(this, index, data.id)}
                htmlFor="ordersSelectOne"
              >
                &nbsp;
              </label>
            </div>
          </td>
          <td className="orders-order">
          <div className = "row">
            <div className="avatar-group">
              <a
                //  href="profile-posts.html"
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title=""
              >
                <img
                  src=""
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div>
            <div style={{
              whiteSpace: "nowrap",
              width:150,
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>{data.name}</div>
            </div>
          </td>
          <td className="orders-product">
            {/* <div className="avatar-group">
              <a
                href="profile-posts.html"
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title="Kamal Leech"
              >
                <img
                  src={require("../assets/img/sktruser/user-kamal-leech.png")}
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div> */}
            {data.location}
          </td>
          <td className="orders-total">{data.area}</td>
          <td className="orders-total">
            {/* <div className="avatar-group">
              <a
                href="profile-posts.html"
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title="Urban Clap"
              >
                <img
                  src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")}
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div> */}
            {data.company_code}
          </td>
          <td className="goal-project">
            {/* <div className="avatar-group">
              <a
                href="profile-posts.html"
                className="avatar avatar-xs mr-3"
                data-toggle="tooltip"
                title="Urban Clap"
              >
                <img
                  src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")}
                  className="avatar-img rounded-circle"
                  alt="..."
                />
              </a>
            </div> */}
            {primary_users.UserPhone1}
          </td>

          {/* <td className="orders-status">
            <div className="badge badge-warning">{data.company_code}</div>
          </td> */}
          <td className="orders-status">
            <div>{data.registration_num}</div>
          </td>
          {/* <td className="orders-date">
            <time dateTime="2018-07-30">{data.registration_num}</time>
          </td> */}
          <td className="orders-method">
            {common.NewformatDate(data.contract_end)}
          </td>
          <span ref={node => (this.node = node)}>
            <td className="text-right">
              <Dropdown className="dropdown">
                <Dropdown.Toggle>
                  <i
                    className="fe fe-more-vertical"
                    // onClick={this.handleAccept.bind(this,index)}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.editClient.bind(this, data)}>
                    Edit Client
                  </Dropdown.Item>
                  {/* <Dropdown.Item onClick={this.DropAlert.bind(this)}>Another action</Dropdown.Item>
    <Dropdown.Item onClick={this.DropAlert.bind(this)}>Something else</Dropdown.Item> */}
                  <Dropdown.Item onClick= {()=>this.builtup(data)} >
                    BuiltUp Unit
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </span>
        </tr>
      );
    });
  };
  DropAlert = () => {
    alert("Hello Dashboard");
  };
  DropAlertActivate = () => {
    confirm(
      "Are You Sure your select company goes to archive",
      "OK",
      "Cancel"
    ).then(
      () => {
        this.Archivecompany();
      },
      () => {
        this.setState({
          message: "cancel"
        });
      }
    );
    // alert('Hello Dashboard');
  };
  render() {
    return (
      <div className="main-content">
        <Notification ref="Notification" />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              {/* Header */}
              <div className="header mt-md-5">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Pretitle */}
                      {/* <h6 className="header-pretitle">Clients</h6> */}
                      {/* Title */}
                      <h1 className="header-title">Clients</h1>
                    </div>
                    <div className="col-auto">
                      {/* Button */}

                      <Link
                        className="btn btn-primary lift"
                        to="/admin/addclient"
                      >
                        Add company
                      </Link>

                      {/* <a href="#" className="btn btn-primary lift">
                      Add company
                      </a> */}
                    </div>
                  </div>{" "}
                  {/* / .row */}
                  {/* <div className="row align-items-center">
                    <div className="col">
                      {/* Nav ////
                      <ul className="nav nav-tabs nav-overflow header-tabs">
                        <li className="nav-item">
                          <a href="#!" onClick = {()=>{this.setState({filteredData : this.state.responseData,all:true,accepted:false,pending:false,process:false,cancel:false})}} className={this.state.all?"nav-link active":"nav-link"}>
                            All{" "}
                            <span className="badge badge-pill badge-soft-secondary">
                              823
                            </span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#!" onClick = {()=>{this.setState({filteredData : this.state.acceptData,all:false,accepted:true,pending:false,process:false,cancel:false})}} className={this.state.accepted?"nav-link active":"nav-link"}>
                            Accepted{" "}
                            <span className="badge badge-pill badge-soft-secondary">
                              71
                            </span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#!" onClick = {()=>{this.setState({filteredData : this.state.pendingData,all:false,accepted:false,pending:true,process:false,cancel:false})}} className={this.state.pending?"nav-link active":"nav-link"}>
                            Pending{" "}
                            <span className="badge badge-pill badge-soft-secondary">
                              2
                            </span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#!" onClick = {()=>{this.setState({filteredData : this.state.processData,all:false,accepted:false,pending:false,process:true,cancel:false})}} className={this.state.process?"nav-link active":"nav-link"}>
                            In process{" "}
                            <span className="badge badge-pill badge-soft-secondary">
                              6
                            </span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#!" onClick = {()=>{this.setState({filteredData : this.state.cancelData,all:false,accepted:false,pending:false,process:false,cancel:true})}} className={this.state.cancel?"nav-link active":"nav-link"}>
                            Cancelled{" "}
                            <span className="badge badge-pill badge-soft-secondary">
                              12
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* Card */}
              <div
                className="card"
                data-toggle="lists"
                data-options='{"valueNames": ["orders-order", "orders-product", "orders-date", "orders-total", "orders-status", "orders-method"]}'
              >
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Search */}
                      <form className="row align-items-center">
                        <div className="col-auto pr-0">
                          <span className="fe fe-search text-muted" />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-flush search"
                            placeholder="Search"
                            onChange={event =>
                              this.searchFunc(event.target.value)
                            }
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col-auto">
                      {/* Button */}
                      <button
                        className="btn btn-sm btn-white dropdown-toggle"
                        type="button"
                        id="bulkActionDropdown"
                        onClick={this.DropAlertActivate.bind(this)}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Archive Company
                      </button>
                      {/* <Dropdown className="dropdown">
                        <Dropdown.Toggle>
                          <button
                            className="btn btn-sm btn-white dropdown-toggle"
                            type="button"
                            id="bulkActionDropdown"
                            onClick={this.handleBulk}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Archive Company
                          </button>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={this.DropAlert.bind(this)}>
                            Select Company
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.DropAlert.bind(this)}>
                            Archive from (date)
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.DropAlert.bind(this)}>
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                      {/* <div className="dropdown">
                        <button
                          className="btn btn-sm btn-white dropdown-toggle"
                          type="button"
                          id="bulkActionDropdown"
                          onClick={this.handleBulk}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Archive Company
                        </button>
                        <div
                          className={
                            this.state.bulk
                              ? "dropdown-menu dropdown-menu-right show"
                              : "dropdown-menu dropdown-menu-right"
                          }
                          aria-labelledby="bulkActionDropdown"
                        >
                          <a className="dropdown-item" href="#!">
                          Select Company
                          </a>
                          <a className="dropdown-item" href="#!">
                          Archive from (date)
                          </a>
                          
                        </div>
                      </div> */}
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>
                          <div className="custom-control custom-checkbox table-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="ordersSelect"
                              id="ordersSelectAll"
                              checked={this.state.alcheck ? true : false}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="ordersSelectAll"
                              onClick={this.handleAllcheckbox.bind(this)}
                            >
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "name")}
                            className="text-muted sort"
                            data-sort="orders-order"
                          >
                            Company Name
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "username")}
                            className="text-muted sort"
                            data-sort="orders-product"
                          >
                            okurr Location
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "email")}
                            className="text-muted sort"
                            data-sort="orders-date"
                          >
                            Area leased
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "company_code")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Company Code
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "company")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Contact number
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(
                              this,
                              "registration_num"
                            )}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Registration number
                          </a>
                        </th>
                        <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "contract_end")}
                            className="text-muted sort"
                            data-sort="orders-total"
                          >
                            Booked Upto
                          </a>
                        </th>
                        {/* <th>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "date")}
                            className="text-muted sort"
                            data-sort="orders-status"
                          >
                            Date
                          </a>
                        </th> */}
                        <th colSpan={2}>
                          <a
                            href="#"
                            onClick={this.sortfunc.bind(this, "role")}
                            className="text-muted sort"
                            data-sort="orders-method"
                          >
                            Action
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {this.display()}

                      {/* <tr>
                  <td>
                    <div className="custom-control custom-checkbox table-checkbox">
                      <input type="checkbox" className="custom-control-input" name="ordersSelect" id="ordersSelectOne" />
                      <label className="custom-control-label" htmlFor="ordersSelectOne">
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td className="orders-order">
                    Client EE
                  </td>
                  <td className="orders-product">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Kamal Leech">
                        <img src={require("../assets/img/sktruser/user-kamal-leech.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Kamal Leech
                  </td>
                  <td className="orders-total">
                    Kamalleech@urbanclap.com
                  </td>
                  <td className="orders-total">
                    +91-956-989-8020
                  </td>
                  <td className="goal-project">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Urban Clap">
                        <img src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Urban Clap
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-warning">
                      In process
                    </div>
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-light">
                      Waiting
                    </div>
                  </td>
                  <td className="orders-date">
                    <time dateTime="2018-07-30">07/30/18</time>
                  </td>
                  <td className="orders-method">
                    NA
                  </td>
                  <td className="text-right">
                    <div className="dropdown">
                      <a 
                      href="#" 
                      className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical" onClick={this.handleAccept}/>
                      </a>
                      <div className={this.state.accept?"dropdown-menu dropdown-menu-right show":"dropdown-menu dropdown-menu-right"}>
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox table-checkbox">
                      <input type="checkbox" className="custom-control-input" name="ordersSelect" id="ordersSelectOne" />
                      <label className="custom-control-label" htmlFor="ordersSelectOne">
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td className="orders-order">
                    Client EE
                  </td>
                  <td className="orders-product">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Kamal Leech">
                        <img src={require("../assets/img/sktruser/user-kamal-leech.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Kamal Leech
                  </td>
                  <td className="orders-total">
                    Kamalleech@urbanclap.com
                  </td>
                  <td className="orders-total">
                    +91-956-989-8020
                  </td>
                  <td className="goal-project">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Urban Clap">
                        <img src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Urban Clap
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-warning">
                      In process
                    </div>
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-light">
                      Waiting
                    </div>
                  </td>
                  <td className="orders-date">
                    <time dateTime="2018-07-30">07/30/18</time>
                  </td>
                  <td className="orders-method">
                    NA
                  </td>
                  <td className="text-right">
                    <div className="dropdown">
                      // <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox table-checkbox">
                      <input type="checkbox" className="custom-control-input" name="ordersSelect" id="ordersSelectOne" />
                      <label className="custom-control-label" htmlFor="ordersSelectOne">
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td className="orders-order">
                    Client EE
                  </td>
                  <td className="orders-product">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Kamal Leech">
                        <img src={require("../assets/img/sktruser/user-kamal-leech.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Kamal Leech
                  </td>
                  <td className="orders-total">
                    Kamalleech@urbanclap.com
                  </td>
                  <td className="orders-total">
                    +91-956-989-8020
                  </td>
                  <td className="goal-project">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Urban Clap">
                        <img src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Urban Clap
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-warning">
                      In process
                    </div>
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-light">
                      Waiting
                    </div>
                  </td>
                  <td className="orders-date">
                    <time dateTime="2018-07-30">07/30/18</time>
                  </td>
                  <td className="orders-method">
                    NA
                  </td>
                  <td className="text-right">
                    <div className="dropdown">
                      // <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox table-checkbox">
                      <input type="checkbox" className="custom-control-input" name="ordersSelect" id="ordersSelectOne" />
                      <label className="custom-control-label" htmlFor="ordersSelectOne">
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td className="orders-order">
                    Client EE
                  </td>
                  <td className="orders-product">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Kamal Leech">
                        <img src={require("../assets/img/sktruser/user-kamal-leech.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Kamal Leech
                  </td>
                  <td className="orders-total">
                    Kamalleech@urbanclap.com
                  </td>
                  <td className="orders-total">
                    +91-956-989-8020
                  </td>
                  <td className="goal-project">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Urban Clap">
                        <img src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Urban Clap
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-warning">
                      In process
                    </div>
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-light">
                      Waiting
                    </div>
                  </td>
                  <td className="orders-date">
                    <time dateTime="2018-07-30">07/30/18</time>
                  </td>
                  <td className="orders-method">
                    NA
                  </td>
                  <td className="text-right">
                    <div className="dropdown">
                      // <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox table-checkbox">
                      <input type="checkbox" className="custom-control-input" name="ordersSelect" id="ordersSelectOne" />
                      <label className="custom-control-label" htmlFor="ordersSelectOne">
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td className="orders-order">
                    Client EE
                  </td>
                  <td className="orders-product">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Kamal Leech">
                        <img src={require("../assets/img/sktruser/user-kamal-leech.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Kamal Leech
                  </td>
                  <td className="orders-total">
                    Kamalleech@urbanclap.com
                  </td>
                  <td className="orders-total">
                    +91-956-989-8020
                  </td>
                  <td className="goal-project">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Urban Clap">
                        <img src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Urban Clap
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-warning">
                      In process
                    </div>
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-light">
                      Waiting
                    </div>
                  </td>
                  <td className="orders-date">
                    <time dateTime="2018-07-30">07/30/18</time>
                  </td>
                  <td className="orders-method">
                    NA
                  </td>
                  <td className="text-right">
                    <div className="dropdown">
                      // <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox table-checkbox">
                      <input type="checkbox" className="custom-control-input" name="ordersSelect" id="ordersSelectOne" />
                      <label className="custom-control-label" htmlFor="ordersSelectOne">
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td className="orders-order">
                    Client EE
                  </td>
                  <td className="orders-product">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Kamal Leech">
                        <img src={require("../assets/img/sktruser/user-kamal-leech.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Kamal Leech
                  </td>
                  <td className="orders-total">
                    Kamalleech@urbanclap.com
                  </td>
                  <td className="orders-total">
                    +91-956-989-8020
                  </td>
                  <td className="goal-project">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Urban Clap">
                        <img src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Urban Clap
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-warning">
                      In process
                    </div>
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-light">
                      Waiting
                    </div>
                  </td>
                  <td className="orders-date">
                    <time dateTime="2018-07-30">07/30/18</time>
                  </td>
                  <td className="orders-method">
                    NA
                  </td>
                  <td className="text-right">
                    <div className="dropdown">
                      // <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox table-checkbox">
                      <input type="checkbox" className="custom-control-input" name="ordersSelect" id="ordersSelectOne" />
                      <label className="custom-control-label" htmlFor="ordersSelectOne">
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td className="orders-order">
                    Client EE
                  </td>
                  <td className="orders-product">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Kamal Leech">
                        <img src={require("../assets/img/sktruser/user-kamal-leech.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Kamal Leech
                  </td>
                  <td className="orders-total">
                    Kamalleech@urbanclap.com
                  </td>
                  <td className="orders-total">
                    +91-956-989-8020
                  </td>
                  <td className="goal-project">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Urban Clap">
                        <img src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Urban Clap
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-warning">
                      In process
                    </div>
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-light">
                      Waiting
                    </div>
                  </td>
                  <td className="orders-date">
                    <time dateTime="2018-07-30">07/30/18</time>
                  </td>
                  <td className="orders-method">
                    NA
                  </td>
                  <td className="text-right">
                    <div className="dropdown">
                      // <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox table-checkbox">
                      <input type="checkbox" className="custom-control-input" name="ordersSelect" id="ordersSelectOne" />
                      <label className="custom-control-label" htmlFor="ordersSelectOne">
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td className="orders-order">
                    Client EE
                  </td>
                  <td className="orders-product">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Kamal Leech">
                        <img src={require("../assets/img/sktruser/user-kamal-leech.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Kamal Leech
                  </td>
                  <td className="orders-total">
                    Kamalleech@urbanclap.com
                  </td>
                  <td className="orders-total">
                    +91-956-989-8020
                  </td>
                  <td className="goal-project">
                    <div className="avatar-group">
                      <a href="profile-posts.html" className="avatar avatar-xs mr-3" data-toggle="tooltip" title="Urban Clap">
                        <img src={require("../assets/img/sktrclient/sktr-client-urbanclap.png")} className="avatar-img rounded-circle" alt="..." />
                      </a>
                    </div>
                    Urban Clap
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-warning">
                      In process
                    </div>
                  </td>
                  <td className="orders-status">
                    <div className="badge badge-light">
                      Waiting
                    </div>
                  </td>
                  <td className="orders-date">
                    <time dateTime="2018-07-30">07/30/18</time>
                  </td>
                  <td className="orders-method">
                    NA
                  </td>
                  <td className="text-right">
                    <div className="dropdown">
                      // <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </td>
                </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* / .row */}
        </div>
      </div>
    );
  }
}
